import { Button, CenteredPage, ErrorScreen } from "@webapps/shared/components";
import { LoadingScreen } from "components";
import { Form, Formik } from "formik";
import { useInputFocus, useMixpanel } from "hooks";
import type { CountryCode } from "libphonenumber-js";
import { useLibPhoneNumber } from "libs";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import { Country, usePhoneNumberScreenQuery } from "operations";
import { ChangeEvent, ComponentProps, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { required, getCountryFormObject, getCallingCodeFromCountryCode } from "utils";

import CustomPhoneNumberField from "../../../components/organisms/CustomPhoneNumberField";
import RegisterGdprConsent from "../../../components/organisms/RegisterGdprConsent";
import PhoneIcon from "../../../images/icons/ri/phone-home.svg?react";

const INITIAL_VALUES = {
  countryCode: "FR" as Country,
  phoneNumber: "",
};

interface FormValues {
  countryCode: Country;
  gdprAccepted?: boolean;
  phoneNumber: string;
}

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "navLeft" | "title" | "subTitle"> {
  children?: never;
  error?: string | undefined;
  onSubmit: (phoneNumber: string) => Promise<void>;
  resetError: () => void;
  trackPage: () => void;
}

const PhoneNumberScreen: FunctionComponent<Props> = ({
  error,
  navLeft,
  onSubmit,
  subTitle,
  title,
  resetError,
  trackPage,
}) => {
  const { t } = useTranslation();
  const ensureLibPhoneNumber = useLibPhoneNumber();
  const {
    data,
    error: countryError,
    loading,
  } = usePhoneNumberScreenQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const focusInputRef = useInputFocus({ enabled: !loading });
  const mixpanel = useMixpanel((state) => state.mixpanel);

  const initialValues = { ...INITIAL_VALUES, gdprAccepted: false, proAccepted: false };

  const submit = async (values: FormValues) => {
    const { parsePhoneNumber } = await ensureLibPhoneNumber();

    const phonelib = parsePhoneNumber(values.phoneNumber, values.countryCode as CountryCode);
    await onSubmit(phonelib.formatInternational());
  };
  const countryPhoneNumbers = getCountryFormObject(true, data?.countries);

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  if (loading) return <LoadingScreen title={title as string} centered navLeft={navLeft} />;

  if (countryError) {
    return (
      <ErrorScreen
        title={t("phoneNumberScreen.errorPage.title")}
        navLeft={navLeft}
        canReloadPage
        error={t("phoneNumberScreen.errorPage.error")}
        centered
      />
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({ errors, handleSubmit, isSubmitting, isValidating, touched, values, setFieldValue, setFieldTouched }) => {
        const hasError = intersection(keys(errors), keys(touched)).length > 0;

        const selectedCallingCode = getCallingCodeFromCountryCode(values.countryCode, data?.countries);

        const handleClear = () => {
          setFieldValue("phoneNumber", "");
          resetError();
        };

        const handleChangeNumber = async (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;

          if (value && error && resetError) {
            resetError();
          }

          if (!values.countryCode) {
            setFieldValue("phoneNumber", value);
          }

          const { AsYouType } = await ensureLibPhoneNumber();

          const asYouType = new AsYouType(values.countryCode as CountryCode);
          const newPhoneNumber = asYouType.input(value);
          setFieldValue("phoneNumber", newPhoneNumber);
        };

        const bottom = (
          <>
            {(hasError || !!error) && (
              <div className="text-base text-red-600">{hasError ? t("form.fix", { ns: "common" }) : error}</div>
            )}

            <Button
              className="w-full md:max-w-xs md:self-center"
              disabled={isValidating || isSubmitting || hasError || !values.phoneNumber}
              label={t("registerPage.phoneNumberScreen.cta")}
              loading={isValidating || isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <CenteredPage bottom={bottom} navLeft={navLeft} subTitle={subTitle} title={title}>
            <Form className="mt-4 grid grid-cols-1 gap-6">
              <div className="flex flex-col">
                <CustomPhoneNumberField
                  name="phoneNumber"
                  prefixName="countryCode"
                  label={t("registerPage.phoneNumberScreen.phoneNumberLabel")}
                  validate={required}
                  inputProps={{
                    autoComplete: "tel",
                    icon: PhoneIcon,
                    inputMode: "tel",
                    // Formik bug, newValues are sometimes replaced by old ones
                    // https://github.com/jaredpalmer/formik/issues/2083
                    onBlur: () => setFieldTouched,
                    onChange: handleChangeNumber,
                    placeholder: "06 11 12 13 14",
                    type: "tel",
                  }}
                  items={countryPhoneNumbers}
                  ref={focusInputRef}
                  onClear={handleClear}
                  callingCode={selectedCallingCode}
                />
                <p className="mt-2 text-sm text-neutral-600">{t("registerPage.phoneNumberScreen.hint")}</p>
              </div>
              <RegisterGdprConsent />
            </Form>
          </CenteredPage>
        );
      }}
    </Formik>
  );
};

export default PhoneNumberScreen;
