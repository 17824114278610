import { ChargeSourceEnum, ChargesPage_ChargeFragment, ChargesPage_PageInfoFragment } from "@webapps/shared/operations";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { PaginationDirection } from "../pages/ChargesPage/components/ChargesPagination";
import { DateFilter } from "../pages/ChargesPage/components/DateFilters";
import { TableData, sanitizeData } from "../pages/ChargesPage/utils";

export type SortingKey = "date" | "amount";
export const sortingKeys = ["date", "amount"] as SortingKey[];
export type Sorting = { desc: boolean; value: SortingKey };

export const PAGE_LENGTH = 15;

export interface Filters {
  accountTagLabel?: string;
  carIdentifier?: string;
  chargeSources?: ChargeSourceEnum[];
  date?: DateFilter;
  driverNameOrPhoneNumber?: string;
  stationName?: string;
}

export type FilterKey = keyof Filters;

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export type ChargesTableState = {
  chargesCount: number | undefined;
  data: TableData[] | undefined;
  dispatch: (args: ChargesTableAction) => void;
  error: boolean;
  filters: Filters;
  initialChargesCount: number | undefined;
  pageInfo: ChargesPage_PageInfoFragment | undefined;
  pagination: Pagination;
  sorting: Sorting;
};

export enum ChargesTableActionKind {
  RESET_FILTERS = "RESET_FILTERS",
  RESET_PAGINATION = "RESET_PAGINATION",
  RESET_STORE = "RESET_STORE",
  SET_DATA = "SET_DATA",
  SET_ERROR = "SET_ERROR",
  SET_FILTER = "SET_FILTER",
  SET_INITIAL_DATA = "SET_INITIAL_DATA",
  SET_PAGE_INFO = "SET_PAGE_INFO",
  SET_PAGINATION = "SET_PAGINATION",
  SET_SORTING = "SET_SORTING",
}

const getFiltersFromPayload = (
  filters: Filters,
  payload: { key: keyof Filters; value: string | DateFilter | ChargeSourceEnum[] }
) => {
  if (!payload.value || (Array.isArray(payload.value) && payload.value.length === 0)) {
    const { [payload.key]: _, ...rest } = filters; // eslint-disable-line @typescript-eslint/no-unused-vars
    return rest;
  } else if (payload.key === "date" || payload.key === "chargeSources") {
    return { ...filters, [payload.key]: payload.value };
  } else {
    const filtersToKeep = Object.keys(filters).reduce((acc, key) => {
      if (key !== "date" && key !== "chargeSources") {
        return acc;
      }
      return { ...acc, [key]: filters[key] };
    }, {});
    return { ...filtersToKeep, [payload.key]: payload.value };
  }
};

type FilterAction = {
  payload: { key: keyof Filters; value: ChargeSourceEnum[] | DateFilter | string };
  type: ChargesTableActionKind.SET_FILTER;
};

type PaginationAction = {
  payload: PaginationDirection;
  type: ChargesTableActionKind.SET_PAGINATION;
};

type ResetPaginationAction = {
  type: ChargesTableActionKind.RESET_PAGINATION;
};

type ResetFiltersAction = {
  type: ChargesTableActionKind.RESET_FILTERS;
};

type SortingAction = {
  payload: SortingKey;
  type: ChargesTableActionKind.SET_SORTING;
};

type DataAction = {
  payload: { chargesCount: number; data: ChargesPage_ChargeFragment[]; pageInfo: ChargesPage_PageInfoFragment };
  type: ChargesTableActionKind.SET_DATA;
};

type PageInfoAction = {
  payload: ChargesPage_PageInfoFragment;
  type: ChargesTableActionKind.SET_PAGE_INFO;
};

type InitialDataAction = {
  payload: {
    chargesCount: number;
    data: ChargesPage_ChargeFragment[];
    initialChargesCount: number;
    pageInfo: ChargesPage_PageInfoFragment;
  };
  type: ChargesTableActionKind.SET_INITIAL_DATA;
};

type ErrorAction = {
  payload: boolean;
  type: ChargesTableActionKind.SET_ERROR;
};

type ResetAction = {
  type: ChargesTableActionKind.RESET_STORE;
};

export type ChargesTableAction =
  | PaginationAction
  | ResetPaginationAction
  | SortingAction
  | DataAction
  | PageInfoAction
  | InitialDataAction
  | ResetFiltersAction
  | FilterAction
  | ErrorAction
  | ResetAction;

function chargesTableReducer(state: ChargesTableState, action: ChargesTableAction) {
  switch (action.type) {
    case ChargesTableActionKind.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageIndex: action.payload === "left" ? state.pagination.pageIndex - 1 : state.pagination.pageIndex + 1,
        },
      };
    case ChargesTableActionKind.RESET_PAGINATION:
      return {
        ...state,
        pagination: initialChargesTableState.pagination,
      };
    case ChargesTableActionKind.SET_SORTING:
      return {
        ...state,
        sorting: { desc: !state.sorting.desc, value: action.payload },
      };
    case ChargesTableActionKind.SET_FILTER:
      return {
        ...state,
        filters: getFiltersFromPayload(state.filters, action.payload),
      };

    case ChargesTableActionKind.SET_DATA:
      return {
        ...state,
        chargesCount: action.payload.chargesCount,
        data: action.payload.data && action.payload.data.length > 0 ? sanitizeData(action.payload.data) : undefined,
        pageInfo: action.payload.pageInfo,
      };
    case ChargesTableActionKind.SET_INITIAL_DATA:
      return {
        ...state,
        chargesCount: action.payload.chargesCount,
        data: action.payload.data && action.payload.data.length > 0 ? sanitizeData(action.payload.data) : undefined,
        initialChargesCount: action.payload.initialChargesCount,
        pageInfo: action.payload.pageInfo,
      };
    case ChargesTableActionKind.SET_PAGE_INFO:
      return {
        ...state,
        pageInfo: action.payload,
      };
    case ChargesTableActionKind.RESET_FILTERS:
      return {
        ...state,
        filters: {},
      };
    case ChargesTableActionKind.SET_ERROR: {
      const newState = action.payload ? initialChargesTableState : state;
      return {
        ...newState,
        error: action.payload,
      };
    }
    case ChargesTableActionKind.RESET_STORE:
      return {
        ...initialChargesTableState,
      };
    default:
      return state;
  }
}

export const initialChargesTableState = {
  chargesCount: undefined,
  data: undefined,
  error: false,
  filters: {},
  initialChargesCount: undefined,
  pageInfo: undefined,
  pagination: {
    pageIndex: 0,
    pageSize: PAGE_LENGTH,
  },
  sorting: {
    desc: true,
    value: "date" as SortingKey,
  },
};

export const useChargesState = create<ChargesTableState>()(
  devtools((set) => ({
    ...initialChargesTableState,
    dispatch: (args: ChargesTableAction) => set((state) => chargesTableReducer(state, args)),
  }))
);
