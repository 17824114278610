import { Button } from "@webapps/shared/components";
import classNames from "classnames";
import ArrowDownIcon from "images/icons/ri/chevron-down.svg?react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "usehooks-ts";

import FilterSelectItem from "./FilterSelectItem";

export type FilterSelectItem<T> = { label: string; value: T };

interface Props<T> {
  children?: never;
  desc?: boolean;
  items: FilterSelectItem<T>[];
  kind: "checkbox" | "radio" | "sort";
  label: string;
  onClick?: (value?: T) => void;
  onItemClick: (value: T) => void;
  selectedItems: FilterSelectItem<T>[] | undefined;
  size: "small" | "medium" | "large";
}

const FilterSelect = <T,>({ selectedItems, onItemClick, items, label, onClick, size, kind, ...rest }: Props<T>) => {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);

  const handleSelectClick = () => {
    setVisible((prev) => !prev);
  };

  const handleItemClick = (value: T) => {
    onItemClick(value);
    if (kind === "radio") {
      closeItems();
    }
  };

  const closeItems = () => setVisible(false);

  useOnClickOutside(ref, closeItems);

  const hasMultipleSelected = selectedItems && selectedItems.length > 1;
  const hasOneSelected = selectedItems && selectedItems.length === 1;
  const allSelected = selectedItems && selectedItems.length === items.length;

  const selectedLabel = allSelected
    ? t("filterSelect.all")
    : hasMultipleSelected
      ? selectedItems.map((item) => item?.label).join(", ")
      : hasOneSelected
        ? selectedItems[0].label
        : "";

  return (
    <div className="relative flex flex-col gap-1" ref={ref}>
      <div
        className={classNames(
          "border-primary-default flex h-8 cursor-pointer justify-between rounded-sm border border-opacity-10 bg-white p-2 text-sm transition-all",
          {
            "w-[11.1rem]": size === "medium",
            "w-[13rem]": size === "large",
            "w-[8.5rem]": size === "small",
          }
        )}
        onClick={handleSelectClick}
      >
        <div>
          <span className="mr-1 font-bold">{label}</span>
          <span>{selectedLabel}</span>
        </div>
        <ArrowDownIcon
          className={classNames("h-4 w-4 flex-none", {
            "rotate-180": isVisible,
          })}
        />
      </div>
      {isVisible && (
        <div
          className={classNames(
            "border-primary-default absolute top-9 z-50 flex flex-col items-start rounded-sm border border-opacity-10 bg-white px-2 py-1",
            {
              "w-[11.1rem]": size === "medium",
              "w-[13rem]": size === "large",
              "w-[8.5rem]": size === "small",
            }
          )}
        >
          {items.map(({ value, label }, index) => {
            const selected = hasMultipleSelected
              ? selectedItems.some((item) => item.value === value)
              : hasOneSelected
                ? selectedItems[0].value === value
                : false;
            return (
              <FilterSelectItem
                key={`${value}-${index}`}
                label={label}
                selected={selected}
                value={value}
                kind={kind}
                onClick={handleItemClick}
                isLast={index === items.length - 1}
                {...rest}
              />
            );
          })}
          {kind !== "radio" && (
            <div className="flex w-full justify-between pb-1 pt-2">
              <Button size="small" kind="tertiary" label={t("filterSelect.cancel")} onClick={closeItems} />
              <Button
                size="small"
                kind="secondary"
                label={t("filterSelect.apply")}
                onClick={() => {
                  onClick?.();
                  closeItems();
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterSelect;
