import { useMediaQuery } from "@react-hook/media-query";
import { LINK_TO_DOWNLOAD_APP } from "@webapps/constants";
import { Button, CenteredPage } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { useTranslation, Trans } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

import ScanAppQrCode from "../../../components/molecules/ScanAppQrCode";
import { usePeriodicPaymentMode } from "../../../hooks/usePeriodicPaymentMode";
import { useProType } from "../../../hooks/useProType";
import useSignOut from "../../../hooks/useSignOut";
import { useUser } from "../../../hooks/useUser";
import HandIcon from "../../../images/icons/ri/hand.svg?react";

type Props = { trackPage: () => void };

const ConfirmationScreen: FunctionComponent<Props> = ({ trackPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mixpanel = useMixpanel((state) => state.mixpanel);
  const { userId, currentAccountUserId } = useUser();
  const { isFleet, proTypeLabel } = useProType();
  const isStorybook = import.meta.env.STORYBOOK;
  const signOut = useSignOut();
  const periodicPaymentMode = usePeriodicPaymentMode();
  const isSepa = periodicPaymentMode === "sepa";
  const subtitleKey = isSepa
    ? `registerPage.confirmationScreen.sepa.subtitle`
    : `registerPage.confirmationScreen.${proTypeLabel}.subtitle`;

  const smScreen = useMediaQuery("only screen and (min-width: 640px)");

  const pushToGtm = useCallback(() => {
    if (window.dataLayer && userId && proTypeLabel && !isStorybook && currentAccountUserId) {
      window.dataLayer.push({
        account_type: proTypeLabel,
        account_user_id: currentAccountUserId,
        event: "register_complete",
        leanplum_user_id: userId,
      });
    }
  }, [userId, proTypeLabel, isStorybook, currentAccountUserId]);

  // if user is fleet, we want him to use the app
  // if user is independant on mobile, we redirect to store
  // otherwise, we show the download message
  const handleClick = useMemo(() => {
    if (isFleet) {
      const to = isSepa
        ? {
            pathname: "/payment",
          }
        : {
            pathname: "/users/invite",
            search: createSearchParams({
              welcome: "true",
            }).toString(),
          };

      return () => navigate(to, { replace: true });
    }
    if (isIOS || isAndroid) {
      return () => (window.location.href = LINK_TO_DOWNLOAD_APP);
    }
    return undefined;
  }, [isFleet, navigate, isSepa]);

  const bottom = useMemo(() => {
    if (!handleClick && smScreen) return <ScanAppQrCode />;

    return (
      <Button
        className="w-full md:max-w-xs md:self-center"
        label={t(`registerPage.confirmationScreen.${proTypeLabel}.cta`)}
        onClick={handleClick}
        type="button"
        size="large"
      />
    );
  }, [handleClick, smScreen, proTypeLabel, t]);

  useEffect(() => {
    if (!isFleet) {
      // independant drivers must be signed out if their registration is completed
      signOut();
    }
  }, [isFleet, signOut]);

  useEffect(() => {
    pushToGtm();
  }, [pushToGtm]);

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  return (
    <CenteredPage size="large" bottom={bottom} bottomWidthLimitation={!handleClick && smScreen ? false : true}>
      <div className="flex h-full flex-col items-center justify-center gap-4 sm:mt-4 sm:justify-start sm:px-4">
        <HandIcon className="hidden w-[7.25rem] self-start sm:block" />
        <div className="text-primary-default flex flex-col gap-8">
          <h1 className="my-0 text-5xl font-light">{t(`registerPage.confirmationScreen.${proTypeLabel}.title`)}</h1>
          <div className="text-base font-light">
            <Trans
              i18nKey={subtitleKey}
              components={{ 1: <span className="font-bold" />, 2: <span className="whitespace-nowrap" /> }}
            />
          </div>
        </div>
      </div>
    </CenteredPage>
  );
};

export default ConfirmationScreen;
