import { FieldError, Input } from "@webapps/shared/components";
import { FieldWrapperProps } from "@webapps/shared/components/molecules/FormFields/FieldWrapper";
import { useField } from "formik";
import { ComponentProps, forwardRef } from "react";

import LoadingText from "../../atoms/LoadingText";

export interface Props extends FieldWrapperProps {
  children?: never;
  inputProps?: ComponentProps<typeof Input>;
  loading?: boolean;
  onClear?: () => void;
  serverError?: boolean;
  validate?: (value: string) => string | undefined;
}

const InputItemField = forwardRef<HTMLInputElement, Props>(
  ({ inputProps, label, name, validate, onClear, loading, serverError = false }, ref) => {
    const [field, { error, initialValue, touched }, { setValue }] = useField<string>({ name, validate });

    const handleClear = () => {
      if (onClear) {
        onClear();
      } else setValue((inputProps?.initialValue as string) ?? initialValue ?? "", false);
    };

    return (
      <div className="text-label-sm items center flex flex-col gap-2 md:grid md:grid-cols-3 md:gap-6">
        <label className="text-label-sm font-bold text-black">{label}</label>
        <div className="md:col-span-2">
          {loading ? (
            <LoadingText colorMode="medium" />
          ) : (
            <Input
              ref={ref}
              state={(error && touched) || serverError ? "invalid" : "none"}
              onClear={handleClear}
              {...field}
              {...inputProps}
              serverError={serverError}
              variant={"small"}
            />
          )}
          <FieldError name={name} variant="small" />
        </div>
      </div>
    );
  }
);

export default InputItemField;
