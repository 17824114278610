import { useMediaQuery } from "@react-hook/media-query";
import InvoiceIcon from "@webapps/shared/images/icons/ri/invoice.svg?react";
import { motion, AnimatePresence } from "framer-motion";
import { AccountFragment } from "operations";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useMobileMenu } from "../../../hooks/useMobileMenu";
import { useIsSepaPayment } from "../../../hooks/usePeriodicPaymentMode";
import Logo from "../../../images/business-logo.svg?react";
import BoltIcon from "../../../images/icons/ri/bolt.svg?react";
import DashboardIcon from "../../../images/icons/ri/dashboard.svg?react";
import PlugIcon from "../../../images/icons/ri/plug.svg?react";
import SettingsIcon from "../../../images/icons/ri/settings.svg?react";
import UserIcon from "../../../images/icons/ri/user.svg?react";
import DrawerLink from "../../atoms/DrawerLink";
import Separator from "../../atoms/Separator";
import AccountSelector from "../../molecules/AccountSelector";
import { ResponsiveCloseAction } from "../../molecules/BusinessAction";

// we don't use this value with a template literal in the className as it won't work
export const MENU_WIDTH = 72;

interface Props {
  currentAccount: AccountFragment | undefined;
  hasManyAccounts?: boolean;
  onAccountSelectorClick: () => void;
  signedIn?: boolean;
}

const Drawer: FunctionComponent<Props> = ({
  currentAccount,
  hasManyAccounts = false,
  onAccountSelectorClick,
  signedIn = false,
}) => {
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");
  const closeMenu = useMobileMenu((state) => state.closeMenu);
  const opened = useMobileMenu((state) => state.opened);
  const isSepa = useIsSepaPayment();

  const { t } = useTranslation();
  const iconClassNames = "mr-2 w-6 text-white opacity-20";

  return (
    <AnimatePresence>
      {opened && !mdScreen ? (
        <motion.div
          key="overlay"
          className="fixed inset-0 z-30 bg-black md:hidden"
          onClick={closeMenu}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0 }}
        />
      ) : null}
      {opened || mdScreen ? (
        <motion.aside
          key="aside"
          className={`p-4-safe md:px-6-safe md:py-12-safe md:shadow-drawer-business fixed inset-y-0 z-40 flex w-72 max-w-full flex-col bg-neutral-700 transition-all md:left-0`}
          initial={{ x: mdScreen ? 0 : "-100%" }}
          animate={{ x: 0 }}
          transition={{ bounce: 0, duration: 0.2 }}
          exit={{ x: mdScreen ? 0 : "-100%" }}
        >
          <div className="mb-4 flex h-11 flex-none items-center p-3 md:hidden">
            <ResponsiveCloseAction className="text-white" onClick={closeMenu} />
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="hidden flex-none md:flex">
              <DrawerLink route={{ to: "/" }}>
                <Logo className="h-10 text-white" />
              </DrawerLink>
            </div>

            <Separator className="hidden md:block" />

            <div className="flex flex-col gap-2">
              {!signedIn && (
                <>
                  <DrawerLink route={{ to: { pathname: "/register", search: "?account_type=fleet" } }}>
                    <UserIcon className={iconClassNames} />
                    {t("menu.register.fleet")}
                  </DrawerLink>
                  <DrawerLink route={{ to: { pathname: "/register", search: "?account_type=independant" } }}>
                    <UserIcon className={iconClassNames} />
                    {t("menu.register.independant")}
                  </DrawerLink>
                  <DrawerLink route={{ to: "/sign-in" }}>
                    <UserIcon className={iconClassNames} />
                    {t("menu.signin")}
                  </DrawerLink>
                </>
              )}
              {!!currentAccount?.accountUser?.canShowDashboard && (
                <DrawerLink route={{ to: "/dashboard" }}>
                  <DashboardIcon className={iconClassNames} />
                  {t("menu.dashboard")}
                </DrawerLink>
              )}

              {!!currentAccount?.accountUser?.canListUsers && (
                <DrawerLink
                  route={{
                    globalRoutes: ["/support"],
                    isRootPath: true,
                    subRoutes: ["/invitations"],
                    to: "/users",
                  }}
                >
                  <UserIcon className={iconClassNames} />
                  {t("menu.users")}
                </DrawerLink>
              )}

              <DrawerLink route={{ to: "/stations" }}>
                <PlugIcon className={iconClassNames} />
                {t("menu.map")}
              </DrawerLink>

              {signedIn && (
                <>
                  <DrawerLink route={{ to: "/charges" }}>
                    <BoltIcon className={iconClassNames} />
                    {t("menu.history")}
                  </DrawerLink>
                  {isSepa && (
                    <DrawerLink route={{ to: "/payment" }}>
                      <InvoiceIcon className={iconClassNames} />
                      {t("menu.payment")}
                    </DrawerLink>
                  )}
                </>
              )}
            </div>

            <Separator />

            <div className="flex flex-col gap-2">
              {currentAccount && hasManyAccounts ? (
                <div className="px-3">
                  <AccountSelector account={currentAccount} onClick={onAccountSelectorClick} />
                </div>
              ) : null}

              {!!currentAccount?.accountUser && (
                <DrawerLink route={{ to: "/user-info" }}>
                  <UserIcon className={iconClassNames} />
                  {t("menu.infos")}
                </DrawerLink>
              )}

              <DrawerLink
                route={{
                  globalRoutes: ["/support"],
                  subRoutes: ["/sign-out", "/user-deletion-intents"],
                  to: "/settings",
                }}
              >
                <SettingsIcon className={iconClassNames} />
                {t("menu.settings")}
              </DrawerLink>
            </div>
          </div>
          <Separator className="md:hidden" />
          <div className="flex-none md:hidden">
            <DrawerLink route={{ to: "/" }}>
              <Logo className="h-10 text-white" />
            </DrawerLink>
          </div>
        </motion.aside>
      ) : null}
    </AnimatePresence>
  );
};

export default Drawer;
