import { ChargesPage_ChargeFragment } from "@webapps/shared/operations";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ResponsiveMenuAction } from "../../components/molecules/BusinessAction";
import { ChargesTableActionKind, PAGE_LENGTH, useChargesState } from "../../hooks/useChargesState";
import { useUser } from "../../hooks/useUser";
import NoAccountSelectedScreen from "../../screens/NoAccountSelectedScreen";

import useCharges from "./hooks/useCharges";
import useChargesQueryVariables from "./hooks/useChargesQueryVariables";
import Screen from "./screens/Screen";

const ChargesPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const pageTitle = t("chargesPage.pageTitle");
  const currentAccountId = useUser((state) => state.currentAccountId);
  const dispatch = useChargesState((state) => state.dispatch);
  const pagination = useChargesState((state) => state.pagination);
  const { getCharges } = useCharges();
  const chargesQueryVariables = useChargesQueryVariables();

  useEffect(() => {
    const setChargeData = async () => {
      const result = await getCharges({
        variables: {
          ...chargesQueryVariables,
          first: PAGE_LENGTH,
        },
      });
      if (result.data?.me?.account?.charges?.nodes) {
        dispatch({
          payload: {
            chargesCount: result.data.me.account.chargesCount,
            data: result.data.me.account.charges.nodes as ChargesPage_ChargeFragment[],
            initialChargesCount: result.data.me.account.chargesCount,
            pageInfo: result.data.me.account.charges.pageInfo,
          },
          type: ChargesTableActionKind.SET_INITIAL_DATA,
        });
        if (pagination.pageIndex !== 0) {
          dispatch({ type: ChargesTableActionKind.RESET_PAGINATION });
        }
      }
    };
    setChargeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Props
   */

  const commonProps = useMemo(() => {
    return {
      navLeft: <ResponsiveMenuAction />,
      title: pageTitle,
    };
  }, [pageTitle]);

  //
  // Rendering
  //

  if (!currentAccountId) {
    return <NoAccountSelectedScreen {...commonProps} />;
  }

  return <Screen {...commonProps} />;
};

export default ChargesPage;
