import { BottomSheetWrapper, Footer, NotificationContainer } from "components";
import { useNotification } from "hooks";
import {
  useSwitchAccountMutation,
  NotificationCategoryEnum,
  useLayoutQuery,
  AccountValidationStatus,
} from "operations";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { ChargesTableActionKind, useChargesState } from "../../../hooks/useChargesState";
import useLastSwitchedAccountId from "../../../hooks/useLastSwitchedAccountId";
import { useMobileMenu } from "../../../hooks/useMobileMenu";
import useSignedIn from "../../../hooks/useSignedIn";
import useSignOut from "../../../hooks/useSignOut";
import { useUser } from "../../../hooks/useUser";
import getBusinessAccounts from "../../../utils/getBusinessAccounts";
import isBusinessPage from "../../../utils/isBusinessPage";
import AccountSelectorBottomSheet from "../../organisms/BottomSheets/AccountSelectorBottomSheet";
import Drawer from "../../organisms/Drawer";

const Layout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signedIn = useSignedIn();
  const signOut = useSignOut();
  const closeMenu = useMobileMenu((state) => state.closeMenu);
  const { currentAccountId, setUser } = useUser();
  const { data, loading } = useLayoutQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !signedIn,
  });
  const { setLastSwitchedAccountId, lastSwitchedAccountId } = useLastSwitchedAccountId();
  const setNotification = useNotification((state) => state.setNotification);
  const [switchAccountMutation] = useSwitchAccountMutation();
  const [accountSelectorSheetVisible, setAccountSelectorSheetVisible] = useState(false);
  const dispatch = useChargesState((state) => state.dispatch);
  const [searchParams] = useSearchParams();

  const status = data?.me?.onboardingStatus?.status;
  const proTypeLabel = data?.me?.onboardingStatus?.proTypeLabel;
  const businessPage = isBusinessPage();
  const isInProgress = status === AccountValidationStatus.InProgress;
  const redirectToRegister = isInProgress && !loading && location.pathname !== "/register";
  const rawAccounts = data?.me?.accounts?.nodes;
  const accounts = useMemo(() => (rawAccounts ? getBusinessAccounts(rawAccounts) : []), [rawAccounts]);
  const hasManyAccounts = accounts.length > 1;
  const currentAccount = useMemo(
    () => accounts.find((account) => account.id === currentAccountId),
    [accounts, currentAccountId]
  );
  const userId = data?.me?.id;

  const onAccountSelectorClick = useCallback(() => {
    closeMenu();
    setAccountSelectorSheetVisible(true);
  }, [closeMenu]);

  const onCloseAccountSelectorBottomSheet = useCallback(() => {
    setAccountSelectorSheetVisible(false);
  }, []);

  const onSwitchAccount = useCallback(
    async (accountId: string) => {
      const result = await switchAccountMutation({
        variables: {
          input: {
            accountId,
          },
        },
      });

      const hasErrors =
        (result?.errors && result.errors.length > 0) ||
        (result?.data?.switchAccount?.errors && result.data.switchAccount.errors.length > 0);
      const newCurrentAccountId = result.data?.switchAccount?.account?.id;
      const newCurrentAccountUserId = accounts.find((account) => account.id === newCurrentAccountId)?.accountUser?.id;
      if (!hasErrors && newCurrentAccountId && newCurrentAccountUserId && userId) {
        setLastSwitchedAccountId(newCurrentAccountId);
        setUser({
          currentAccountId: newCurrentAccountId,
          currentAccountUserId: newCurrentAccountUserId,
          userId,
        });
        dispatch({
          type: ChargesTableActionKind.RESET_STORE,
        });
        navigate("/", { replace: true });
      } else {
        setNotification({
          message: t("notification.layout.error.message"),
          title: t("notification.layout.error.title"),
          type: NotificationCategoryEnum.Error,
        });
        onCloseAccountSelectorBottomSheet();
      }

      return !hasErrors;
    },
    [
      navigate,
      onCloseAccountSelectorBottomSheet,
      setNotification,
      switchAccountMutation,
      t,
      setLastSwitchedAccountId,
      setUser,
      accounts,
      userId,
      dispatch,
    ]
  );

  const accountSelectorBottomSheet = useMemo(() => {
    if (!accountSelectorSheetVisible || !currentAccount || !currentAccountId) return null;

    return (
      <AccountSelectorBottomSheet
        accounts={accounts}
        onClose={onCloseAccountSelectorBottomSheet}
        onConfirm={onSwitchAccount}
      />
    );
  }, [
    accountSelectorSheetVisible,
    accounts,
    currentAccount,
    onCloseAccountSelectorBottomSheet,
    onSwitchAccount,
    currentAccountId,
  ]);

  /*   useEffect(() => {
    if (currentUserId) {
      initLeanplum(currentUserId);
    }
  }, [currentUserId]); */

  // set the current user/account in zustand store
  useEffect(() => {
    if (accounts.length === 0) return;
    const lastSwitchedAccount = accounts.find((account) => account.id === lastSwitchedAccountId);
    const firstAccount = accounts[0];
    const currentAccount = lastSwitchedAccount ?? firstAccount;
    const currentAccountId = currentAccount.id;
    const currentAccountUserId = currentAccount.accountUser?.id;
    if (!currentAccountId || !currentAccountUserId || !userId) return;
    setUser({
      currentAccountId,
      currentAccountUserId,
      userId,
    });
  }, [accounts, lastSwitchedAccountId, setLastSwitchedAccountId, setUser, userId]);

  // handle a deleted pro account still logged in app, will redirect to sign in
  useEffect(() => {
    if (accounts.length === 0 && !isInProgress && signedIn && status) {
      signOut();
    }
  }, [accounts, signOut, isInProgress, signedIn, status]);

  // handle an onboarding user navigating elsewhere register page
  useEffect(() => {
    if (redirectToRegister) {
      const registerUrl = proTypeLabel ? `/register?account_type=${proTypeLabel}` : "/register";

      navigate(registerUrl, { replace: true });
    }
  }, [navigate, redirectToRegister, proTypeLabel, searchParams]);

  // handle an onboarding user navigating with the wrong protype param
  useEffect(() => {
    const accountType = searchParams.get("account_type");
    if (accountType !== proTypeLabel && proTypeLabel) {
      searchParams.set("account_type", proTypeLabel);
    }
  }, [navigate, redirectToRegister, proTypeLabel, searchParams]);

  if (businessPage) {
    return (
      <>
        <div className="text-primary-default flex h-full flex-col">
          <div className="lg:bg-main-business absolute inset-0 z-[-10] hidden lg:block lg:bg-cover lg:blur-[125px]" />
          <main className="flex flex-grow flex-col md:max-h-screen md:overflow-y-auto">
            <Outlet />
          </main>
          <Footer />
        </div>
        <NotificationContainer />
        <BottomSheetWrapper
          sheet={accountSelectorBottomSheet}
          onClose={onCloseAccountSelectorBottomSheet}
          overAll
          showBackdrop
        />
      </>
    );
  }

  return (
    <div className="text-primary-default absolute inset-0 flex max-w-full flex-col md:pl-72">
      <Drawer
        currentAccount={currentAccount}
        hasManyAccounts={hasManyAccounts}
        onAccountSelectorClick={onAccountSelectorClick}
        signedIn={signedIn}
      />

      <main className="relative flex max-h-screen min-h-full flex-grow flex-col overflow-y-auto md:shadow-xl">
        <Outlet />
      </main>
      <NotificationContainer />
      <BottomSheetWrapper
        sheet={accountSelectorBottomSheet}
        onClose={onCloseAccountSelectorBottomSheet}
        overAll
        showBackdrop
      />
    </div>
  );
};

export default Layout;
