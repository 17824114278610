import { Banner, MissingInfo } from "@webapps/shared/components";
import { PaymentPage_SepaMandatePaymentMethodFragment } from "@webapps/shared/operations";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import SubSubtitle from "../../../components/atoms/SubSubtitle";
import CheckIcon from "../../../images/icons/ri/check-circle.svg?react";
import { IbanFormState } from "../screens/Screen";
import { formatIban } from "../utils";

import IbanForm, { IbanFormValues } from "./IbanForm";
import PaymentItem from "./PaymentItem";

interface Props {
  children?: never;
  ibanFormState: IbanFormState;
  loading?: boolean;
  openConfirmationModal: (formValues: IbanFormValues) => void;
  paymentMethod: PaymentPage_SepaMandatePaymentMethodFragment | undefined;
  showIbanInfo: boolean;
}

const PaymentInfos: FunctionComponent<Props> = ({
  paymentMethod,
  ibanFormState,
  loading,
  openConfirmationModal,
  showIbanInfo,
}) => {
  const { t } = useTranslation();
  const isForm = ibanFormState === "form";

  return (
    <Banner
      borderColor="primary-default"
      borderOpacity="10"
      padding="p-6"
      bgColor="bg-business-background"
      borderRadius="medium"
    >
      <div className="flex w-full flex-col gap-4">
        <SubSubtitle>{t("paymentPage.account.payment.title")}</SubSubtitle>
        {isForm ? (
          <IbanForm onConfirm={openConfirmationModal} />
        ) : (
          <div className="flex flex-col">
            <PaymentItem
              label={t("paymentPage.account.payment.account")}
              loading={loading}
              value={
                paymentMethod?.holderName && showIbanInfo ? (
                  paymentMethod.holderName
                ) : (
                  <MissingInfo label={t("paymentPage.account.payment.infoRequired")} />
                )
              }
            />
            <PaymentItem
              label={t("paymentPage.account.payment.iban")}
              loading={loading}
              value={
                paymentMethod?.truncatedIdentifier && showIbanInfo ? (
                  formatIban(paymentMethod.truncatedIdentifier, "•")
                ) : (
                  <MissingInfo label={t("paymentPage.account.payment.infoRequired")} />
                )
              }
            />
            {!!paymentMethod?.truncatedIdentifier && (
              <PaymentItem
                label={t("paymentPage.account.payment.sepa")}
                loading={loading}
                value={
                  ibanFormState === "signed" ? (
                    <div className="flex items-center gap-2">
                      <CheckIcon className="h-4 w-4 text-green-500" /> {t("paymentPage.account.payment.signed")}
                    </div>
                  ) : (
                    <MissingInfo label={t("paymentPage.account.payment.signatureRequired")} />
                  )
                }
              />
            )}
          </div>
        )}
      </div>
    </Banner>
  );
};

export default PaymentInfos;
