import Pagination from "@webapps/apps/business/src/components/molecules/Pagination";
import { ChargesTableActionKind, PAGE_LENGTH, useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";

import useCharges from "../../hooks/useCharges";

export type PaginationDirection = "left" | "right";

const ChargesPagination = () => {
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const state = useChargesState();
  const { dispatch, pagination, chargesCount } = state;

  const { getNextCharges } = useCharges();
  const { trackButtonClicked } = useMixpanel();

  const firstRowIndex = pagination.pageIndex * PAGE_LENGTH + 1;
  const lastRowIndex = Math.min(firstRowIndex + PAGE_LENGTH - 1, chargesCount ?? 0);

  const onPaginationClick = async (direction: PaginationDirection) => {
    await getNextCharges(direction);

    trackButtonClicked(BUTTON_CLICKED.CHARGES_PAGINATION, {
      ...mixpanelDynamicProps,
      pagination_direction: direction,
    });
    dispatch({ payload: direction, type: ChargesTableActionKind.SET_PAGINATION });
  };

  const paginationProps = {
    firstRowIndex,
    lastRowIndex,
    onLeftClick: () => onPaginationClick("left"),
    onRightClick: () => onPaginationClick("right"),
    rowCount: chargesCount ?? 0,
  };

  return <Pagination {...paginationProps} />;
};

export default ChargesPagination;
