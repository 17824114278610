import { ChargesTableActionKind, useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { Button } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import classNames from "classnames";
import ArrowDownIcon from "images/icons/ri/chevron-down.svg?react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "usehooks-ts";

import DateRangeField from "../../../../components/molecules/DateRangeField";
import useCharges from "../../hooks/useCharges";

import { CustomDateFilter } from ".";

const CustomFilter = () => {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(false);
  const dispatch = useChargesState((state) => state.dispatch);
  const filters = useChargesState((state) => state.filters);
  const filter = filters.date;
  const { refreshCharges } = useCharges();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const isCustomFilter = !!(filter && ("startDate" in filter || "endDate" in filter));
  const selected = !!(isCustomFilter && (filter?.startDate || filter?.endDate));
  const ref = useRef(null);

  const handleSelectClick = () => {
    setVisible((prev) => !prev);
  };
  const closeItems = () => setVisible(false);

  useOnClickOutside(ref, closeItems);

  const handleApply = async () => {
    await refreshCharges();
    closeItems();
    trackButtonClicked(BUTTON_CLICKED.FILTER_DATE, {
      ...mixpanelDynamicProps,
      filter_name: "custom",
    });
  };

  const onDateChange = (value: CustomDateFilter) => {
    dispatch({ payload: { key: "date", value }, type: ChargesTableActionKind.SET_FILTER });
  };

  const handleReset = async () => {
    await dispatch({
      payload: { key: "date", value: "" },
      type: ChargesTableActionKind.SET_FILTER,
    });
    refreshCharges();
    closeItems();
  };

  const values = { endDate: (filter as CustomDateFilter)?.endDate, startDate: (filter as CustomDateFilter)?.startDate };

  return (
    <div className="relative flex flex-col gap-1" ref={ref}>
      <div
        className={classNames("flex cursor-pointer justify-between p-2 text-sm transition-all", {
          "bg-filter-select-background": selected,
          "bg-white": !selected,
        })}
        onClick={handleSelectClick}
      >
        <div>
          <span className={classNames("mr-1")}>{t("chargesPage.filters.date.custom")}</span>
        </div>
        <ArrowDownIcon
          className={classNames("h-4 w-4 flex-none", {
            "rotate-180": isVisible,
          })}
        />
      </div>
      {isVisible && (
        <div
          className={classNames(
            "border-primary-default z-100 absolute right-0 top-9 flex w-[18rem] flex-col items-start rounded-sm border border-opacity-10 bg-white px-4 pb-2 pt-6 shadow-lg sm:right-auto"
          )}
        >
          <div className="flex max-w-[30rem] flex-col gap-8">
            <div className="flex flex-col gap-4 text-left">
              <DateRangeField onDateChange={onDateChange} values={values} />
            </div>
            <div className="flex justify-end">
              <Button
                size="small"
                kind="tertiary"
                label={t("chargesPage.filters.reset")}
                onClick={handleReset}
                disabled={false}
              />
              <Button
                disabled={false}
                size="small"
                kind="secondary"
                label={t("chargesPage.filters.apply")}
                onClick={handleApply}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomFilter;
