import { ChargesTableActionKind, useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import { Toggle } from "@webapps/shared/components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import useCharges from "../../hooks/useCharges";

interface Props {
  children?: never;
  isFilterVisible: boolean;
  toggleFilter: (state: boolean) => void;
}

const FilterToggle: FunctionComponent<Props> = ({ toggleFilter, isFilterVisible }) => {
  const { t } = useTranslation();
  const dispatch = useChargesState((state) => state.dispatch);
  const { refreshCharges } = useCharges();

  const handleToggle = () => {
    toggleFilter(!isFilterVisible);
    if (isFilterVisible) {
      dispatch({ type: ChargesTableActionKind.RESET_FILTERS });
      refreshCharges();
    }
  };

  return (
    <Toggle
      onLabel={t("chargesPage.filters.on")}
      offLabel={t("chargesPage.filters.off")}
      isChecked={isFilterVisible}
      handleToggle={handleToggle}
    />
  );
};

export default FilterToggle;
