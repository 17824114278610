import { BusinessCompanyFragment } from "@webapps/shared/operations";
import { t } from "i18next";

export const getBillingItems = (accountInfo: BusinessCompanyFragment | undefined) => {
  const addressString = `${accountInfo?.address ?? ""} ${!!accountInfo?.address && ","} ${accountInfo?.city ?? ""} ${accountInfo?.postCode ?? ""}${(!!accountInfo?.city || !!accountInfo?.postCode) && ","} ${accountInfo?.countryString ?? ""}`;
  const items = [
    { label: t("paymentPage.account.billing.company", { ns: "business" }), value: accountInfo?.name },
    { label: t("paymentPage.account.billing.vat", { ns: "business" }), value: accountInfo?.vatIdNumber },
    { label: t("paymentPage.account.billing.siret", { ns: "business" }), value: accountInfo?.siretNumber },
    { label: t("paymentPage.account.billing.address", { ns: "business" }), value: addressString },
    { label: t("paymentPage.account.billing.email", { ns: "business" }), value: accountInfo?.billingEmail },
  ];
  return accountInfo ? items.filter((item) => !!item.value) : items;
};

export const getSigningConfiguration = (url: string) => {
  return {
    displayFormat: "focused",
    style: {
      branding: {
        primaryButton: {
          backgroundColor: "#0B2936",
          borderRadius: "1000px",
          color: "#43F5BA",
        },
      },
      signingNavigationButton: {
        finishText: t("paymentPage.account.payment.cta"),
        position: "bottom-right",
      },
    },
    url,
  };
};

export const formatIban = (value: string, placeholder: string = "") => {
  return value
    .toUpperCase()
    .replace(/[^\dA-Z]/g, placeholder)
    .replace(/(.{4})/g, "$1 ")
    .replace(/(.{2})/, "$1 ")
    .trim();
};
