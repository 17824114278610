import { ChargeSortKeyEnum, SortDirectionEnum } from "@webapps/shared/operations";
import { useMemo } from "react";

import { useChargesState } from "../../../hooks/useChargesState";
import { useUser } from "../../../hooks/useUser";

export const sortingVariablesByState = {
  amount: ChargeSortKeyEnum.TotalAmountCents,
  date: ChargeSortKeyEnum.At,
};

const useChargesQueryVariables = () => {
  const currentAccountId = useUser((state) => state.currentAccountId);
  const sorting = useChargesState.getState().sorting;

  const { desc, value } = sorting;
  const variables = useMemo(() => {
    return {
      accountId: currentAccountId ?? "none",
      sort: {
        sortBy: sortingVariablesByState[value],
        sortDirection: desc ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
      },
    };
  }, [currentAccountId, desc, value]);

  return variables;
};

export default useChargesQueryVariables;
