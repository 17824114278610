import FilterSelect from "@webapps/apps/business/src/components/molecules/FilterSelect";
import { ChargesTableActionKind, FilterKey, useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { Button, Input } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "../../../../images/icons/ri/search.svg?react";
import useCharges from "../../hooks/useCharges";

const searchFilters: FilterKey[] = ["accountTagLabel", "carIdentifier", "driverNameOrPhoneNumber", "stationName"];

const SearchFilter = () => {
  const { t } = useTranslation();
  const dispatch = useChargesState((state) => state.dispatch);
  const filters = useChargesState((state) => state.filters);
  const { refreshCharges } = useCharges();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const [payload, setPayload] = useState<{ key: FilterKey | undefined; value: string }>({
    key: undefined,
    value: "",
  });
  const [disableApply, setApplyDisabled] = useState(true);

  const hasSomeValue = Object.keys(filters).some((key) => filters[key as FilterKey]);

  const onSelectItemClick = (key: FilterKey) => {
    setPayload({ key, value: !payload?.key ? payload.value : "" });
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setPayload({ key: payload?.key, value: e.target.value });
  };

  const handleSearch = async () => {
    if (!payload?.key || !payload?.value) {
      return;
    }
    dispatch({
      payload: payload as { key: FilterKey; value: string },
      type: ChargesTableActionKind.SET_FILTER,
    });
    await refreshCharges();
    setApplyDisabled(true);
    trackButtonClicked(BUTTON_CLICKED.VIEW_CHARGES, {
      ...mixpanelDynamicProps,
      filter_name: payload.key,
    });
  };

  const handleReset = async () => {
    if (!payload?.key) {
      return;
    }
    dispatch({
      payload: { key: payload.key, value: "" },
      type: ChargesTableActionKind.SET_FILTER,
    });
    await refreshCharges();
    setPayload({ key: undefined, value: "" });
    trackButtonClicked(BUTTON_CLICKED.RESET_CHARGES, {
      ...mixpanelDynamicProps,
    });
  };

  const items: { label: string; value: FilterKey }[] = searchFilters.map((key) => ({
    label: t(`chargesPage.filters.${key}.label`),
    value: key,
  }));

  const selectedItems = items.find((item) => item.value === payload?.key);

  return (
    <div className="flex flex-col items-start gap-2 sm:flex-row md:flex-col lg:flex-row">
      <FilterSelect
        items={items}
        kind="radio"
        selectedItems={selectedItems ? [selectedItems] : undefined}
        label={t("chargesPage.filters.searchBy")}
        size="large"
        onItemClick={onSelectItemClick}
      />

      <Input
        value={payload?.value}
        onChange={handleInputChange}
        placeholder={payload?.key ? t(`chargesPage.filters.${payload.key}.placeholder`) : ""}
        className="w-[18.1rem]"
        variant="small"
        icon={SearchIcon}
        borderColor="border-neutral-100"
        fullWidth={false}
        onFocus={() => setApplyDisabled(false)}
      />
      <div className="flex flex-row gap-2">
        <Button
          size="small"
          kind="secondary"
          label={t("chargesPage.filters.apply")}
          onClick={handleSearch}
          disabled={disableApply || !payload?.key || !payload?.value}
        />
        <Button
          size="small"
          kind="secondary"
          label={t("chargesPage.filters.reset")}
          onClick={handleReset}
          disabled={(!payload?.value && !payload?.key) || !hasSomeValue}
        />
      </div>
    </div>
  );
};

export default SearchFilter;
