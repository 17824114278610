import { FunctionComponent } from "react";

import { TableData, renderData } from "../../utils";

interface Props {
  children?: never;
  className?: string;
  tableData: TableData | {};
}

const ChargeCard: FunctionComponent<Props> = ({ tableData }) => {
  const data = renderData(tableData, true);

  return (
    <div className="shadow-charge-card flex flex-col gap-2 rounded p-4 text-sm">
      {data.map(({ cell, header }, index) => {
        return (
          <div key={`row-${index}`} className="flex gap-4">
            <div className="w-[6.25rem] shrink-0 grow-0 basis-[6.25rem] font-bold">{header()}</div>
            <div key={`header-${index}`} className="flex font-normal">
              {cell()}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChargeCard;
