import { AccountMissingInfos } from "operations";

export const getScreenByMissingInfo = (missingInfos: AccountMissingInfos[]) => {
  if (missingInfos.includes(AccountMissingInfos.Company)) {
    return "COMPANY";
  } else if (missingInfos.includes(AccountMissingInfos.Documents)) {
    return "DOCUMENTS";
  }
  return "ONBOARDING";
};
