import { useTranslation } from "react-i18next";

import ActionBanner from "../../../components/molecules/ActionBanner";

const SepaUsersBanner = ({ onSetupClick }: { onSetupClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <ActionBanner
      ctaLabel={t("paymentPage.banner.sepa.cta")}
      onClick={onSetupClick}
      subtitle={t("paymentPage.banner.sepa.subtitle")}
      title={t("paymentPage.banner.sepa.title")}
    />
  );
};

export default SepaUsersBanner;
