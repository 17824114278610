import { ChargesTableActionKind, Filters, useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { DateFilterEnum } from "@webapps/shared/operations";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useCharges from "../../hooks/useCharges";

import CustomFilter from "./CustomFilter";

export type CustomDateFilter = {
  endDate: Date | undefined;
  startDate: Date | undefined;
};

export type AppDateFilter = {
  dateFilterType: DateFilterEnum;
};

export type DateFilter = AppDateFilter | CustomDateFilter;

// It is impossible to order the enum values with graphql from the back-end
// https://stackoverflow.com/questions/74623455/how-to-ensure-enum-order-in-graphql
const OrderedDateFilters = [
  DateFilterEnum.Today,
  DateFilterEnum.Yesterday,
  DateFilterEnum.Wtd,
  DateFilterEnum.Mtd,
  DateFilterEnum.Qtd,
  DateFilterEnum.Ytd,
];

const DateFilters = () => {
  const { t } = useTranslation();
  const filters = useChargesState((state) => state.filters);
  const dispatch = useChargesState((state) => state.dispatch);
  const { refreshCharges } = useCharges();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const filter = filters.date;

  const onDateFilterClick = async (value: AppDateFilter, selected: boolean) => {
    const payload = { key: "date" as keyof Filters, value: selected ? "" : value };
    dispatch({ payload, type: ChargesTableActionKind.SET_FILTER });
    await refreshCharges();
    trackButtonClicked(BUTTON_CLICKED.FILTER_DATE, {
      ...mixpanelDynamicProps,
      filter_name: value.dateFilterType,
    });
  };

  return (
    <div
      className={classNames(
        "border-primary-default flex justify-between rounded-sm border border-opacity-10 bg-white text-sm"
      )}
    >
      <div className="flex">
        {OrderedDateFilters.map((dateFilter, index) => {
          const selected = filter && "dateFilterType" in filter && filter?.dateFilterType === dateFilter;
          return (
            <button
              key={`${dateFilter}-${index}`}
              className={classNames(
                "border-r-primary-default cursor-pointer border-r border-opacity-10 px-[6px] py-2 text-center sm:px-4",
                {
                  "bg-filter-select-background": selected,
                  "bg-white": !selected,
                }
              )}
              onClick={() => onDateFilterClick({ dateFilterType: dateFilter }, !!selected)}
              type="button"
            >
              {t(`chargesPage.filters.date.${dateFilter}`)}
            </button>
          );
        })}
      </div>
      <CustomFilter />
    </div>
  );
};

export default DateFilters;
