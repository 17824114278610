import { Button, ErrorScreen, InfoModal, LoadingScreen, CenteredPage } from "@webapps/shared/components";
import ClockIcon from "@webapps/shared/images/icons/ri/clock.svg?react";
import { Form, Formik } from "formik";
import { useMixpanel, useNotification } from "hooks";
import { BUTTON_CLICKED } from "libs";
import { Country, DocumentFileDataType, DocumentType, useDocumentsScreenQuery } from "operations";
import { ChangeEventHandler, ComponentProps, FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import { useOnboardingStatus } from "../../../hooks/useOnboardingStatus";
import { useUser } from "../../../hooks/useUser";
import DocumentField from "../components/DocumentField";

type FormValues = Record<DocumentType, File[]>;

const MAX_FILE_SIZE = 5 * 1024 * 1024;

interface Props extends Pick<ComponentProps<typeof CenteredPage>, "subTitle"> {
  children?: never;
  closeModal: () => void;
  error?: string | ReactElement | undefined;
  isModalOpen: boolean;
  onSubmit: (documentFiles: DocumentFileDataType[]) => Promise<void>;
  resetError: () => void;
  trackPage: () => void;
}

const DocumentsScreen: FunctionComponent<Props> = ({
  error,
  onSubmit,
  subTitle,
  resetError,
  isModalOpen,
  closeModal,
  trackPage,
}) => {
  const { t } = useTranslation();
  const [validateError, setValidateError] = useState<string | undefined>();
  const mixpanel = useMixpanel((state) => state.mixpanel);
  const setNotification = useNotification((state) => state.setNotification);
  const trackButtonClicked = useMixpanel((state) => state.trackButtonClicked);
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const { userId, currentAccountUserId } = useUser();

  const submit = async (values: FormValues) => {
    const documentsFiles = Object.keys(values).map((key) => {
      const files = values[key as DocumentType].map((file) => new File([file], file.name));
      return {
        documentType: key as DocumentType,
        files,
      };
    });
    await onSubmit(documentsFiles);
  };

  const onboardingStatus = useOnboardingStatus();
  const countryKey = onboardingStatus?.country?.key ?? Country.Fr;

  const { data, loading } = useDocumentsScreenQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { countryKey },
  });

  const requiredDocuments = data?.businessCompanyRequiredDocuments;

  useEffect(() => {
    if (!mixpanel) return;

    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixpanel]);

  if (loading || !requiredDocuments) {
    return <LoadingScreen centered />;
  }

  if (requiredDocuments.length === 0) {
    return <ErrorScreen canReloadPage error={t("api.occuredError", { ns: "common" })} navLeft={undefined} centered />;
  }

  const defaultValues: FormValues = requiredDocuments?.reduce((acc, value) => {
    acc[value.documentType] = [];
    return acc;
  }, {} as FormValues);

  return (
    <Formik initialValues={defaultValues} onSubmit={submit}>
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        const disableButton =
          Object.keys(values).some((field) => values[field as DocumentType].length === 0) || !!error || isSubmitting;

        const formError = error || validateError;

        const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
          if (validateError) {
            setValidateError(undefined);
          }
          if (error) {
            resetError();
          }
          const files = event.target.files;
          const name = event.target.name as DocumentType;
          if (!event.target.validity.valid || !files) {
            setValidateError(t("registerPage.documentsScreen.error.invalid"));
            return;
          }
          const filesArray = Array.from(files);
          const alreadyUploaded = values[name].some((file) => file.name === filesArray[0].name);
          const tooBig = filesArray[0].size > MAX_FILE_SIZE;
          if (alreadyUploaded || tooBig) {
            setValidateError(t(`registerPage.documentsScreen.error.${alreadyUploaded ? "alreadyUploaded" : "tooBig"}`));
            setFieldValue(name, values[name]);
          } else {
            const buttonName = BUTTON_CLICKED[name];
            trackButtonClicked(buttonName, {
              account_user_id: currentAccountUserId,
              context: "smb_onboarding",
              user_id: userId,
              ...mixpanelDynamicProps,
            });

            setFieldValue(name, [...values[name], filesArray[0]]);
          }
        };

        const handleDelete = (field: DocumentType, name: string) => {
          if (validateError) {
            setValidateError(undefined);
          }
          if (error) {
            resetError();
          }
          const newFiles = values[field].length === 1 ? [] : values[field].filter((file) => file.name !== name);
          setFieldValue(field, newFiles);
          setNotification({
            message: t("notification.register.success"),
          });
        };

        const bottom = (
          <>
            {!!formError && <div className="text-base text-red-600">{formError}</div>}

            <Button
              className="w-full md:max-w-xs md:self-center"
              disabled={disableButton}
              label={t("registerPage.documentsScreen.cta")}
              loading={isSubmitting}
              onClick={handleSubmit}
              size="large"
              type="submit"
            />
          </>
        );

        return (
          <>
            <InfoModal
              closeModal={closeModal}
              isOpen={isModalOpen}
              showCloseButton={false}
              icon={ClockIcon}
              buttonClassName="mt-2"
              title={t("registerPage.infoModal.title")}
              subtitle={t("registerPage.infoModal.subtitle")}
              cta={t("registerPage.infoModal.cta")}
            />

            <CenteredPage bottom={bottom} subTitle={subTitle} title={t("registerPage.documentsScreen.pageTitle")}>
              <p className="text-base font-light text-neutral-700">{t("registerPage.documentsScreen.description")}</p>
              <Form className="-mx-2 mt-4 grid grid-cols-1 gap-8 rounded bg-neutral-50 px-4 py-6 md:mx-0">
                {Object.keys(defaultValues).map((value) => {
                  const document = requiredDocuments?.find((doc) => doc.documentType === value);
                  return (
                    <DocumentField
                      key={value}
                      name={value as DocumentType}
                      handleChange={handleChange}
                      handleDelete={handleDelete}
                      disabled={isSubmitting}
                      label={document ? document.label : ""}
                      sublabel={document ? document.sublabel : ""}
                    />
                  );
                })}
              </Form>
            </CenteredPage>
          </>
        );
      }}
    </Formik>
  );
};

export default DocumentsScreen;
