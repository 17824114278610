import classNames from "classnames";
import CheckboxIcon from "images/icons/ri/checkbox-circle-fill-2.svg?react";
import { FunctionComponent } from "react";

export type IconColor = "light" | "dark";

type Props = {
  children?: never;
  list: { iconColor?: IconColor; text: string }[];
  reverse?: boolean;
};

const CheckedList: FunctionComponent<Props> = ({ list, reverse = false }) => (
  <div className="flex flex-col gap-3">
    {list.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames("text-secondary-default flex items-center gap-2", {
            "flex-row": !reverse,
            "flex-row-reverse justify-between": reverse,
          })}
        >
          <CheckboxIcon
            className={classNames("h-6 w-6 flex-none self-start", {
              "text-icon-default": item.iconColor === "dark",
              "text-neutral-300": item.iconColor === "light" || !item.iconColor,
            })}
          />
          <div className="text-medium font-normal">{item.text}</div>
        </div>
      );
    })}
  </div>
);

export default CheckedList;
