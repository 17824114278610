import { FunctionComponent } from "react";

interface Props {
  children?: never;
  label: string;
}

const MissingInfo: FunctionComponent<Props> = ({ label }) => {
  return (
    <div className="flex items-center gap-3">
      <div className="h-2 w-2 flex-none rounded-full bg-orange-500" />
      <span className="text-secondary-default text-label-sm font-normal">{label}</span>
    </div>
  );
};

export default MissingInfo;
