import FilterSelect from "@webapps/apps/business/src/components/molecules/FilterSelect";
import {
  ChargesTableActionKind,
  SortingKey,
  sortingKeys,
  useChargesState,
} from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { useTranslation } from "react-i18next";

import useCharges from "../../hooks/useCharges";

const SortBySelect = () => {
  const { t } = useTranslation();
  const dispatch = useChargesState((state) => state.dispatch);
  const sorting = useChargesState((state) => state.sorting);
  const { refreshCharges } = useCharges();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const onItemClick = (value: SortingKey) => {
    dispatch({ payload: value, type: ChargesTableActionKind.SET_SORTING });
  };

  const onClick = async () => {
    const { value } = sorting;
    await refreshCharges();

    trackButtonClicked(BUTTON_CLICKED.SORT_CHARGES, {
      ...mixpanelDynamicProps,
      sorting_key: value,
    });
  };

  const items = sortingKeys.map((key) => ({ label: t(`chargesPage.sortBySelect.${key}`), value: key }));
  const selectedItem = items.find((item) => item.value === sorting.value) || items[0];

  return (
    <FilterSelect
      items={items}
      kind="sort"
      selectedItems={[selectedItem]}
      label={t("chargesPage.sortBySelect.label")}
      desc={sorting.desc}
      size="medium"
      onItemClick={onItemClick}
      onClick={onClick}
    />
  );
};

export default SortBySelect;
