import { BUSINESS_TOKEN_STORAGE_KEY } from "constants/storage";

import { CloseToBackAction, LoadingScreen, ErrorScreen } from "components";
import { useAppLocation, useLocalStorage, useMixpanel } from "hooks";
import { PAGE_VIEWED } from "libs";
import join from "lodash/join";
import {
  usePhoneNumberScreenQuery,
  useSignInPageStartPhoneChallengeMutation,
  useSignInPageCompletePhoneChallengeMutation,
  useSignInPageMutation,
} from "operations";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, createSearchParams, useNavigate } from "react-router-dom";
import { PageError, getCountryFormObject } from "utils";

import { ResponsiveBackAction } from "../../components/molecules/BusinessAction";
import useLastSwitchedAccountId from "../../hooks/useLastSwitchedAccountId";
import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";
import useSignedIn from "../../hooks/useSignedIn";
import PhoneNumberScreen from "../../screens/PhoneNumberScreen";
import canUserUseApp from "../../utils/canUserUseApp";
import getBusinessAccounts from "../../utils/getBusinessAccounts";
import hasToRedirectUserToSelfServe from "../../utils/hasToRedirectUserToSelfServe";
import redirectUserToMobile from "../../utils/redirectUserToMobile";

import PhoneChallengeScreen from "./screens/PhoneChallengeScreen";

interface Props {
  children?: never;
}

const SignInPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t("signInPage.pageTitle");
  const signedIn = useSignedIn();
  const location = useAppLocation();
  const [, setSessionToken] = useLocalStorage(BUSINESS_TOKEN_STORAGE_KEY);
  const { lastSwitchedAccountId } = useLastSwitchedAccountId();

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const [challengeToken, setChallengeToken] = useState<string>();
  const [phoneChallengeCompletionToken, setPhoneChallengeCompletionToken] = useState<string>();
  const [phoneChallengeError, setPhoneChallengeError] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberError, setPhoneNumberError] = useState<string>();
  const [screen, setScreen] = useState<"PHONE_NUMBER" | "PHONE_CHALLENGE">("PHONE_NUMBER");
  const [displayRedirectToApp, setDisplayRedirectToApp] = useState(false);

  const [startPhoneChallengeMutation] = useSignInPageStartPhoneChallengeMutation({
    onCompleted: ({ startPhoneChallenge: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setPhoneNumber(undefined);
        setPhoneNumberError(join(result.errors, " "));
        return;
      }

      if (result?.challengeToken) {
        setPhoneNumberError(undefined);
        setChallengeToken(result.challengeToken);
        setScreen("PHONE_CHALLENGE");
        return;
      }
      setPhoneNumber(undefined);
      setPhoneNumberError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setPhoneNumber(undefined);
      setPhoneNumberError(error.message);
    },
  });
  const [completePhoneChallengeMutation] = useSignInPageCompletePhoneChallengeMutation({
    onCompleted: async ({ completePhoneChallenge: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setPhoneChallengeError(join(result.errors, " "));
        return;
      }

      const completionToken = result?.completionToken ?? undefined;
      if (completionToken) {
        setPhoneChallengeCompletionToken(completionToken);

        await signInMutation({
          variables: {
            input: {
              phoneChallengeCompletionToken: completionToken,
            },
          },
        });
        return;
      }

      setPhoneChallengeError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setPhoneChallengeError(error.message);
    },
  });
  const [signInMutation] = useSignInPageMutation({
    onCompleted: ({ signIn: result }) => {
      if (result?.errors && result.errors.length > 0) {
        setPhoneChallengeError(join(result.errors, " "));
        return;
      }

      if (result?.sessionToken && result.user) {
        setPhoneChallengeError(undefined);
        const { accounts, onboardingStatus } = result.user;
        const businessAccounts = accounts?.nodes ? getBusinessAccounts(accounts.nodes) : [];
        const canUseApp = onboardingStatus ? canUserUseApp(onboardingStatus) : false;
        const hasBusinessAccount = businessAccounts.length > 0;
        const lastSwitchedAccount = businessAccounts.find((account) => account.id === lastSwitchedAccountId);
        const firstAccount = businessAccounts[0];
        const currentAccount = lastSwitchedAccount ?? firstAccount;

        // we only sign-in authorized users
        if (canUseApp && hasBusinessAccount) {
          setSessionToken(result.sessionToken);
          const hasNoMembers =
            currentAccount?.members?.nodes?.length === 0 && currentAccount?.invitations?.nodes?.length === 0;
          const redirectToSepa =
            currentAccount?.proAccountInfo?.periodicPaymentMode === "sepa" &&
            currentAccount?.proAccountInfo?.sepaMandate?.status !== "active";
          if (redirectToSepa) {
            navigate(
              {
                pathname: "/payment",
              },
              { replace: true }
            );
            return;
          }

          if (hasNoMembers) {
            navigate(
              {
                pathname: "/users/invite",
                search: createSearchParams({
                  welcome: "true",
                }).toString(),
              },
              { replace: true }
            );
          } else {
            const origin = location.state?.from?.pathname || "/";
            navigate(origin, { replace: true });
          }
          return;
        }

        // we redirect in progress users to self serve, params will behandled by the useProType hook
        if (hasToRedirectUserToSelfServe(onboardingStatus?.missingInfos)) {
          setSessionToken(result.sessionToken);
          navigate("/register");
          return;
        }

        // we display a redirection to mobile app for independant onboarded users
        if (redirectUserToMobile(onboardingStatus)) {
          setDisplayRedirectToApp(true);
          return;
        }

        // we display info for rejected users
        setPhoneChallengeError(t("registerPage.error.rejected"));
        return;
      }
      setPhoneChallengeError(t("api.unknownError", { ns: "common" }));
    },
    onError: (error) => {
      setPhoneChallengeError(error.message);
    },
  });

  const { data, loading, error } = usePhoneNumberScreenQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const countryPhoneNumbers = getCountryFormObject(true, data?.countries);

  //
  // Tracking plan
  //

  const trackPage = useCallback(() => {
    if (screen !== "PHONE_NUMBER" && screen !== "PHONE_CHALLENGE") return;

    trackPageView(
      screen === "PHONE_NUMBER" ? PAGE_VIEWED.SIGN_IN_PHONE_NUMBER_SCREEN : PAGE_VIEWED.SIGN_IN_CHALLENGE_CODE_SCREEN,
      mixpanelDynamicProps
    );
  }, [mixpanelDynamicProps, screen, trackPageView]);

  /*
   * Props
   */

  const phoneNumberScreenProps = useMemo(() => {
    if (screen !== "PHONE_NUMBER") return;

    return {
      countries: countryPhoneNumbers,
      error: phoneNumberError,
      isSignInPage: true,
      onSubmit: async (phoneNumber: string) => {
        setPhoneNumber(phoneNumber);

        await startPhoneChallengeMutation({
          variables: {
            input: {
              phoneNumber,
            },
          },
        });
      },
      resetError: () => setPhoneNumberError(undefined),
      trackPage,
    };
  }, [screen, countryPhoneNumbers, phoneNumberError, trackPage, startPhoneChallengeMutation]);

  const phoneChallengeScreenProps = useMemo(() => {
    if (screen !== "PHONE_CHALLENGE" || !phoneNumber) return;

    return {
      displayRedirectToApp,
      error: phoneChallengeError,
      navLeft: (
        <ResponsiveBackAction
          onClick={() => {
            setPhoneNumber(undefined);
            setPhoneChallengeError(undefined);
            setPhoneChallengeCompletionToken(undefined);
            setScreen("PHONE_NUMBER");
          }}
        />
      ),
      onSubmit: async (code: string) => {
        if (!phoneChallengeCompletionToken) {
          if (!challengeToken) {
            setPhoneChallengeError(t("signInPage.error.timeout"));
            return;
          }

          await completePhoneChallengeMutation({
            variables: {
              input: {
                challengeCode: parseInt(code, 10),
                challengeToken,
              },
            },
          });
          return;
        }

        await signInMutation({
          variables: {
            input: {
              phoneChallengeCompletionToken,
            },
          },
        });
      },
      phoneNumber,
      trackPage,
    };
  }, [
    challengeToken,
    completePhoneChallengeMutation,
    phoneChallengeCompletionToken,
    phoneChallengeError,
    phoneNumber,
    screen,
    signInMutation,
    t,
    displayRedirectToApp,
    trackPage,
  ]);

  if (signedIn) {
    return <Navigate to="/" replace={true} />;
  }

  if (loading)
    return <LoadingScreen title={pageTitle} centered navLeft={<CloseToBackAction withBackground={false} />} />;

  if (error) {
    return (
      <ErrorScreen
        error={error.message}
        centered
        navLeft={<CloseToBackAction withBackground={false} />}
        title={pageTitle}
      />
    );
  }

  if (phoneNumberScreenProps) {
    return <PhoneNumberScreen {...phoneNumberScreenProps} />;
  }

  if (phoneChallengeScreenProps) {
    return <PhoneChallengeScreen {...phoneChallengeScreenProps} />;
  }

  throw new PageError({ pageTitle });
};

export default SignInPage;
