import { FunctionComponent, ReactNode } from "react";

import LoadingText from "../../../components/atoms/LoadingText";

interface Props {
  children?: never;
  label: string;
  loading?: boolean;
  value: string | ReactNode | undefined;
}

const PaymentItem: FunctionComponent<Props> = ({ label, value, loading = false }) => {
  return (
    <div className="text-label-sm border-b-primary-default flex flex-col gap-2 border-b border-opacity-10 py-3 last-of-type:border-b-0 last-of-type:pb-3 md:grid md:grid-cols-3 md:gap-6">
      <label className="font-bold text-black">{label}</label>

      <div className="text-primary-default font-normal md:col-span-2">
        {loading && <LoadingText colorMode="medium" />}
        {(!loading && value) ?? "-"}
      </div>
    </div>
  );
};

export default PaymentItem;
