type Props = {
  handleToggle: () => void;
  isChecked: boolean;
  offLabel: string;
  onLabel: string;
};

const Toggle = ({ onLabel, offLabel, isChecked, handleToggle }: Props) => {
  const label = isChecked ? onLabel : offLabel;

  return (
    <label className="relative inline-flex cursor-pointer select-none items-center">
      <input type="checkbox" checked={isChecked} onChange={handleToggle} className="sr-only" />
      <span className="label text-secondary-default flex items-center whitespace-nowrap text-sm font-medium">
        {label}
      </span>
      <span
        className={`mx-2 flex h-4 w-[1.89rem] items-center rounded-full p-[0.17rem] duration-200 ${
          isChecked ? "bg-brand-600" : "bg-[#CCCCCE]"
        }`}
      >
        <span
          className={`dot h-3 w-3 rounded-full bg-white duration-200 ${isChecked ? "translate-x-[0.875rem]" : ""}`}
        ></span>
      </span>
    </label>
  );
};

export default Toggle;
