import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  bgColor?: string;
  borderColor?: string;
  borderOpacity?: string;
  borderRadius?: "large" | "medium" | "normal" | "small";
  children: ReactNode;
  className?: string;
  padding?: string;
}

const Banner: FunctionComponent<Props> = ({
  className,
  children,
  bgColor,
  borderRadius = "large",
  padding = "p-4",
  borderColor,
  borderOpacity,
}) => {
  return (
    <div
      className={classNames(`flex ${padding} ${bgColor ?? "bg-neutral-100"}`, className, {
        border: !!borderColor,
        rounded: borderRadius === "normal",
        "rounded-lg": borderRadius === "large",
        "rounded-md": borderRadius === "medium",
        "rounded-sm": borderRadius === "small",
        [`border-${borderColor}`]: !!borderColor,
        [`border-opacity-${borderOpacity}`]: !!borderOpacity,
      })}
    >
      {children}
    </div>
  );
};

export default Banner;
