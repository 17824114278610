import DownloadQrcode from "images/download-qrcode-without-corners.svg?react";
import { useTranslation } from "react-i18next";

const ScanAppQrCode = () => {
  const { t } = useTranslation();

  return (
    <div className="border-primary-default hidden flex-row items-center justify-between gap-6 rounded-3xl border border-opacity-10 bg-white px-6 py-4 md:flex">
      <div className="text-label-sm text-secondary-default text-left font-normal">
        {t("redirectToMobileApp.description")}
      </div>
      <DownloadQrcode className="h-[60px] w-[60px] flex-none" />
    </div>
  );
};

export default ScanAppQrCode;
