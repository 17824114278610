import classNames from "classnames";
import CheckMarkIcon from "images/icons/ri/checkmark.svg?react";
import ArrowDownIcon from "images/icons/ri/chevron-down.svg?react";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

export interface CustomSelectItem {
  label?: string;
  value: string;
}

interface Props {
  children?: never;
  className?: string;
  displayedValue: string;
  invalid?: boolean;
  items: CustomSelectItem[];
  name: string;
  scrollToValue?: boolean;
  setValue: (value: string) => void;
  value: string;
}

const CustomSelect = ({
  items,
  displayedValue,
  invalid,
  setValue,
  value,
  name,
  className,
  scrollToValue = false,
}: Props) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);
  const selectedRef = useRef<HTMLLIElement | null>(null);

  const handleSelectClick = () => {
    setVisible((prev) => !prev);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === "Enter") {
      setVisible(true);
    }
  };

  const handleOptionKeyDown: KeyboardEventHandler<HTMLLIElement> = (e) => {
    const newValue = (e.target as HTMLLIElement).getAttribute("data-value");
    if (e.code === "Enter" && newValue) {
      setValue(newValue);
      closeItems();
    }
  };

  const closeItems = () => setVisible(false);

  const handleChange = (value: string) => {
    setValue(value);
    closeItems();
  };

  useOnClickOutside(ref, closeItems);

  useEffect(() => {
    if (isVisible && scrollToValue) {
      selectedRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isVisible, scrollToValue]);

  return (
    <div className={`flex max-w-full flex-col text-base ${className}`} ref={ref}>
      <div
        className={classNames(
          "flex h-14 items-center justify-center rounded-sm border border-neutral-300 bg-white px-2 text-neutral-900 transition-all",
          {
            "border-neutral-300 focus-within:border focus-within:border-neutral-700 focus-within:outline focus-within:outline-2 focus-within:outline-neutral-50":
              !invalid,
            "border-neutral-300 focus-within:border-2 focus-within:border-red-500 focus-within:bg-red-100": invalid,
          }
        )}
        onClick={handleSelectClick}
        onKeyDown={handleKeyDown}
      >
        <input
          className="w-12 cursor-pointer p-0 outline-none ring-0 focus-within:outline-none focus-within:ring-0"
          name={name}
          value={displayedValue}
          readOnly
        />
        <ArrowDownIcon
          className={classNames("h-6 w-6 flex-none", {
            "rotate-180": isVisible,
          })}
        />
      </div>
      {isVisible && (
        <ul
          className={classNames(
            "absolute left-0 right-0 top-16 z-50 flex max-h-[210px] flex-col items-start overflow-y-auto rounded-sm border border-neutral-300 bg-white p-2 lg:max-h-[360px] lg:w-[300px]"
          )}
          tabIndex={1}
        >
          {items.map((item, index) => {
            const selected = item.value === value;
            return (
              <li
                className={classNames(
                  "flex w-full cursor-pointer items-center justify-start p-1 hover:text-neutral-900",
                  {
                    "font-semi-bold text-neutral-900": selected,
                    "text-secondary-default": !selected,
                  }
                )}
                key={`${item.value}-${index}`}
                data-value={item.value}
                onClick={() => handleChange(item.value)}
                tabIndex={index + 1}
                onKeyDown={handleOptionKeyDown}
                ref={selected ? selectedRef : undefined}
              >
                <span>{item.label}</span>
                {selected && <CheckMarkIcon className="ml-auto h-4 w-4" />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
