import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { TableData } from "../../utils";
import ChargeCard from "../ChargeCard";

interface Props {
  data: TableData[] | {}[];
  hasNoResults: boolean;
}

const ChargeCards: FunctionComponent<Props> = ({ data, hasNoResults }) => {
  const { t } = useTranslation();
  return hasNoResults ? (
    <div className="mt-6 w-full text-base">{t("chargesPage.filters.noResult")}</div>
  ) : (
    <div className={classNames("flex h-full flex-col gap-2 px-1")}>
      {data.map((tableData, index) => (
        <ChargeCard key={`${tableData}-${index}`} tableData={tableData} />
      ))}
    </div>
  );
};

export default ChargeCards;
