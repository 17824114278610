import "react-datepicker/dist/react-datepicker.css";
import { Input } from "components";
import { de } from "date-fns/locale/de";
import { enUS } from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { fr } from "date-fns/locale/fr";
import { it } from "date-fns/locale/it";
import { nl } from "date-fns/locale/nl";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { castDateOrString, getCurrentLanguage } from "utils";

import { CustomDateFilter } from "../../../pages/ChargesPage/components/DateFilters";

export interface Props {
  children?: never;
  onDateChange: (value: CustomDateFilter) => void;
  values: CustomDateFilter;
}

const localesByLanguage = {
  de: de,
  en: enUS,
  es: es,
  fr: fr,
  it: it,
  nl: nl,
};

const DateRangeField = ({ onDateChange, values: { startDate, endDate } }: Props) => {
  const { t } = useTranslation();
  const locale = getCurrentLanguage();
  const localeToRegister = localesByLanguage[locale] || enUS;
  const localeString = locale === "en" ? "enUS" : locale;
  registerLocale(localeString, localeToRegister);

  const handleChangeDatePicker = (date: [Date | null, Date | null]) => {
    const newStartDate = date[0] ? castDateOrString(date[0]) : startDate;
    const newEndDate = date[1] ? castDateOrString(date[1]) : date[0] && endDate ? undefined : endDate;

    onDateChange({ endDate: newEndDate, startDate: newStartDate });
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <label className="min-w-10 text-sm">{t("chargesPage.filters.date.start")}</label>
          <Input
            readOnly
            borderColor="border-neutral-100"
            name="startDate"
            placeholder="DD/MM/YY"
            variant="small"
            value={
              startDate
                ? t("format.time", {
                    formatParams: {
                      val: { dateStyle: "short" },
                    },
                    ns: "common",
                    val: castDateOrString(startDate),
                  })
                : ""
            }
          />
        </div>

        <div className="flex items-center gap-2">
          <label className="min-w-10 text-sm">{t("chargesPage.filters.date.end")}</label>
          <Input
            borderColor="border-neutral-100"
            readOnly
            name="endDate"
            placeholder="DD/MM/YY"
            variant="small"
            value={
              endDate
                ? t("format.time", {
                    formatParams: {
                      val: { dateStyle: "short" },
                    },
                    ns: "common",
                    val: castDateOrString(endDate),
                  })
                : ""
            }
          />
        </div>
      </div>
      <DatePicker
        wrapperClassName="w-full text-primary-default"
        selected={startDate}
        onChange={handleChangeDatePicker}
        dateFormat="dd/MM/yyyy"
        locale={locale}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
    </>
  );
};

export default DateRangeField;
