import { useTranslation } from "react-i18next";

import ActionBanner from "../../../components/molecules/ActionBanner";

const HistoricUsersBanner = ({ onSetupClick }: { onSetupClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <ActionBanner
      ctaLabel={t("paymentPage.banner.historic.cta")}
      onClick={onSetupClick}
      subtitle={t("paymentPage.banner.historic.subtitle")}
      title={t("paymentPage.banner.historic.title")}
      bgColor="bg-orange-200"
      withBorder={false}
    />
  );
};

export default HistoricUsersBanner;
