import { ApolloProvider } from "@apollo/client";
import { initMixpanel } from "@webapps/shared/libs";
import { LoadingScreen } from "components";
import { useAppLocation, useMixpanel } from "hooks";
import { FunctionComponent, Suspense, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { version } from "utils";

import useClient from "../hooks/useClient";
import useSignedIn from "../hooks/useSignedIn";
import ChargesPage from "../pages/ChargesPage";
import DashboardPage from "../pages/DashboardPage";
import DownloadChargesPage from "../pages/DownloadChargesPage";
import EmailConfirmationPage from "../pages/EmailConfirmationPage";
import ErrorPage from "../pages/ErrorPage";
import InvitationPage from "../pages/InvitationPage";
import NotFoundPage from "../pages/NotFoundPage";
import PaymentPage from "../pages/PaymentPage";
import PricingPage from "../pages/PricingPage";
import RegisterPage from "../pages/RegisterPage";
import SettingsPage from "../pages/SettingsPage";
import SignInPage from "../pages/SignInPage";
import SignOutPage from "../pages/SignOutPage";
import StationsPage from "../pages/StationsPage";
import SupportChatPage from "../pages/SupportChatPage";
import SupportPage from "../pages/SupportPage";
import UserDeletionIntentNewPage from "../pages/UserDeletionIntentNewPage";
import UserDeletionIntentPage from "../pages/UserDeletionIntentPage";
import UserInfoPage from "../pages/UserInfoPage";
import UserPage from "../pages/UserPage";
import UsersInvitePage from "../pages/UsersInvitePage";
import UsersPage from "../pages/UsersPage";

import Layout from "./templates/Layout";

const App: FunctionComponent<{}> = () => {
  const client = useClient();

  const mixpanelTokenFromEnv = import.meta.env.VITE_MIXPANEL_TOKEN;
  const isDev = import.meta.env.DEV;
  const setMixpanel = useMixpanel((state) => state.setMixpanel);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
          <Route element={<ProtectedRoute />}>
            <Route index element={<UsersPage />} />
            <Route path="users">
              <Route index element={<UsersPage />} />
              <Route element={<UserPage />} path=":accountUserId" />
              <Route element={<UsersInvitePage />} path="invite" />
            </Route>

            <Route path="user-deletion-intents">
              <Route element={<UserDeletionIntentPage />} path=":userDeletionIntentId" />
              <Route element={<UserDeletionIntentNewPage />} path="new" />
            </Route>

            <Route path="charges" element={<ChargesPage />} />
            <Route path="payment" element={<PaymentPage />} />
            <Route element={<InvitationPage />} path="invitations/:invitationId" />
            <Route element={<SignOutPage />} path="sign-out" />
            <Route element={<DashboardPage />} path="dashboard" />
            <Route index element={<UserInfoPage />} path="user-info" />
          </Route>

          <Route path="support">
            <Route element={<SupportPage />} index />
            <Route element={<SupportChatPage />} path="chat" />
          </Route>
          <Route element={<SettingsPage />} path="settings" />
          <Route element={<SignInPage />} path="sign-in" />
          <Route element={<StationsPage />} path="stations" />
          <Route element={<RegisterPage />} path="register" />
          <Route element={<NotFoundPage />} path="*" />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<DownloadChargesPage />} path="/download-csv-export" />
        </Route>
        <Route element={<EmailConfirmationPage />} path="confirm-email/:confirmationToken" />
        <Route element={<PricingPage />} path="/pricing" errorElement={<ErrorPage />} />
      </>
    )
  );

  useEffect(() => {
    const mixpanel = initMixpanel(mixpanelTokenFromEnv, "electra-business", isDev);
    setMixpanel(mixpanel, { app_version: version, product_name: "webapp-b2b" });
  }, [setMixpanel, isDev, mixpanelTokenFromEnv]);

  return (
    <Suspense fallback={<LoadingScreen business />}>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </Suspense>
  );
};

export default App;

const ProtectedRoute = () => {
  const signedIn = useSignedIn();
  const location = useAppLocation();

  if (!signedIn) {
    return <Navigate to="sign-in" replace state={{ from: location }} />;
  }

  return <Outlet />;
};
