import { Button } from "@webapps/shared/components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import ScanAppQrCode from "../../molecules/ScanAppQrCode";

type Props = {
  handleClick?: () => void;
  showDesktopTitle?: boolean;
};

const RedirectToAppBottom: FunctionComponent<Props> = ({ handleClick, showDesktopTitle = true }) => {
  const { t } = useTranslation();

  return !handleClick ? (
    <div className="flex flex-col gap-4">
      {showDesktopTitle && (
        <div className="text-center text-xl font-light text-neutral-700">{t("redirectToMobileApp.desktop.title")}</div>
      )}
      <ScanAppQrCode />
    </div>
  ) : (
    <div className="flex flex-col gap-4 md:max-w-xs md:self-center">
      <div className="text-base font-normal text-neutral-900">{t("redirectToMobileApp.mobile.title")}</div>
      <Button
        className="w-full"
        label={t(`redirectToMobileApp.mobile.cta`)}
        onClick={handleClick}
        type="button"
        size="large"
      />
    </div>
  );
};

export default RedirectToAppBottom;
