import { Page } from "@webapps/shared/components";
import { useModal, useNotification } from "@webapps/shared/hooks";
import InformationIcon from "@webapps/shared/images/icons/ri/information.svg?react";
import {
  NotificationCategoryEnum,
  PaymentPage_ProAccountInfosFragment,
  useCreateSepaMandateMutation,
  useCreateSepaSignatureUrlMutation,
} from "@webapps/shared/operations";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import Subtitle from "../../../components/atoms/Subtitle";
import TitleSeparator from "../../../components/atoms/TitleSeparator";
import { ResponsiveMenuAction } from "../../../components/molecules/BusinessAction";
import QueryError from "../../../components/molecules/QueryError";
import { useIsExternalPayment, useIsSepaPayment } from "../../../hooks/usePeriodicPaymentMode";
import { useUser } from "../../../hooks/useUser";
import BillingInfos from "../components/BillingInfos";
import HistoricUsersBanner from "../components/HistoricUsersBanner";
import { IbanFormValues } from "../components/IbanForm";
import PaymentInfos from "../components/PaymentInfos";
import SepaUsersBanner from "../components/SepaUsersBanner";
import SigninModal from "../components/SigninModal";
import { getSigningConfiguration } from "../utils";

type Props = {
  accountInfo: PaymentPage_ProAccountInfosFragment | undefined;
  error?: boolean;
  loading?: boolean;
  refetch: () => void;
};

export type IbanFormState = "empty" | "form" | "signed";

const Screen: FunctionComponent<Props> = ({ accountInfo, loading, error, refetch }) => {
  const { t } = useTranslation();
  const { isModalDisplayed, setModalDisplayed } = useModal();
  const isExternal = useIsExternalPayment();
  const isSepa = useIsSepaPayment();
  const currentAccountId = useUser((state) => state.currentAccountId);
  const setNotification = useNotification((state) => state.setNotification);
  const hasToSetupSepa = accountInfo?.sepaMandate?.status !== "active";
  const docuSignJwtIntegrationKey = import.meta.env.VITE_DOCUSIGN_JWT_INTEGRATION_KEY;
  const [ibanFormState, setupIbanForm] = useState<IbanFormState>("empty");
  const [ibanFormValues, setIbanFormValues] = useState<IbanFormValues | undefined>(undefined);
  const [showSigning, setShowSigning] = useState(false);
  const [docusignLoading, setDocusignLoading] = useState(false);

  const showErrorNotification = () => {
    setNotification({
      from: "bottom",
      message: t("paymentPage.account.payment.notification.error.message"),
      type: NotificationCategoryEnum.Error,
    });
  };

  const handleCreateSepaSignatureError = () => {
    resetCreateSepaSignatureUrl();
    setModalDisplayed(false);
    showErrorNotification();
  };

  const onSigningSessionEnd = (event: DocuSignSigningEvent, envelopeId: string) => {
    if (event.sessionEndType === "signing_complete" && ibanFormValues && currentAccountId) {
      const { holderName, ibanNumber } = ibanFormValues;
      const formattedIbanNumber = ibanNumber.replace(/\s/g, "");
      createSepaMandate({
        variables: {
          input: {
            accountId: currentAccountId,
            envelopeId,
            holderName,
            ibanNumber: formattedIbanNumber,
          },
        },
      });
    } else {
      showErrorNotification();
    }
  };

  const handleDocusign = async (url: string, envelopeId: string) => {
    setDocusignLoading(true);
    const signingConfiguration = getSigningConfiguration(url);
    try {
      const docusign = await window?.DocuSign?.loadDocuSign(docuSignJwtIntegrationKey);
      const signing = docusign?.signing(signingConfiguration);

      signing?.on("ready", () => {
        setShowSigning(true);
        setModalDisplayed(false);
        setDocusignLoading(false);
      });
      signing?.on("sessionEnd", (event: DocuSignSigningEvent) => onSigningSessionEnd(event, envelopeId));
      signing?.mount(`#docusignContainer`);
    } catch (error) {
      handleCreateSepaSignatureError();
    }
  };

  const [createSepaSignatureUrl, { reset: resetCreateSepaSignatureUrl, loading: loadingCreateSepaSignatureUrl }] =
    useCreateSepaSignatureUrlMutation({
      onCompleted: ({ createSepaSignatureUrl: result }) => {
        if (result?.errors?.length && result?.errors?.length > 0) {
          handleCreateSepaSignatureError();
          return;
        }
        if (result?.signingUrl && result?.envelopeId) {
          handleDocusign(result?.signingUrl, result.envelopeId);
        }
      },
      onError: () => {
        handleCreateSepaSignatureError();
      },
    });

  const [createSepaMandate] = useCreateSepaMandateMutation({
    onCompleted: async ({ createSepaMandate: result }) => {
      if (result?.sepaMandate?.status === "active") {
        refetch();
        setNotification({
          from: "bottom",
          message: t("paymentPage.account.payment.notification.success.message"),
        });
      } else {
        showErrorNotification();
      }
    },
    onError: () => {
      showErrorNotification();
    },
  });

  const onSubmitIbanForm = () => {
    if (!currentAccountId || !ibanFormValues) {
      return;
    }
    const { holderName, ibanNumber } = ibanFormValues;
    const formattedIbanNumber = ibanNumber.replace(/\s/g, "");
    createSepaSignatureUrl({
      variables: {
        input: {
          accountId: currentAccountId,
          holderName,
          ibanNumber: formattedIbanNumber,
        },
      },
    });
  };

  const onSetupClick = () => {
    setupIbanForm("form");
  };

  const openConfirmationModal = (ibanFormValues: IbanFormValues) => {
    setIbanFormValues(ibanFormValues);
    setModalDisplayed(true);
  };

  useEffect(() => {
    if (!hasToSetupSepa) {
      setupIbanForm("signed");
    }
  }, [hasToSetupSepa]);

  return (
    <>
      <SigninModal
        isModalDisplayed={isModalDisplayed}
        setModalDisplayed={setModalDisplayed}
        onSubmitIbanForm={onSubmitIbanForm}
        loading={loadingCreateSepaSignatureUrl || docusignLoading}
        showSigning={showSigning}
      />
      <Page
        title={t("paymentPage.pageTitle")}
        rawContent
        business
        stretchContentOnLargeScreen
        navLeft={<ResponsiveMenuAction />}
      >
        <TitleSeparator />
        {error ? (
          <QueryError />
        ) : (
          <div className="pt-4-safe pb-2-safe md:py-4-safe px-4-safe md:px-8-safe flex flex-col items-start gap-4">
            {isSepa && hasToSetupSepa && ibanFormState !== "form" && !loading && (
              <SepaUsersBanner onSetupClick={onSetupClick} />
            )}
            {isExternal && hasToSetupSepa && ibanFormState !== "form" && !loading && (
              <HistoricUsersBanner onSetupClick={onSetupClick} />
            )}
            <div className="flex w-full flex-col gap-6">
              <Subtitle>{t("paymentPage.account.title")}</Subtitle>
              <div className="flex flex-col justify-between gap-8 md:flex-row">
                <div className="flex-1">
                  <BillingInfos businessCompany={accountInfo?.businessCompany ?? undefined} loading={loading} />
                </div>
                <div className="flex-1">
                  <PaymentInfos
                    paymentMethod={accountInfo?.sepaMandate?.paymentMethod ?? undefined}
                    ibanFormState={ibanFormState}
                    loading={loading}
                    openConfirmationModal={openConfirmationModal}
                    showIbanInfo={!hasToSetupSepa}
                  />
                </div>
              </div>
              <div className="text-label-sm text-secondary-default flex items-center gap-1">
                <InformationIcon className="text-icon-default h-6 w-6" />
                <span className="inline">
                  <Trans
                    i18nKey="paymentPage.account.hint"
                    components={{
                      1: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a className="underline" href="mailto:business@go-electra.com" />
                      ),
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </Page>
    </>
  );
};

export default Screen;
