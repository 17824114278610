import { useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { useUser } from "@webapps/apps/business/src/hooks/useUser";
import { ActionModal, InputField } from "@webapps/shared/components";
import { useInputFocus, useMixpanel, useNotification } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { NotificationCategoryEnum, SortDirectionEnum, useExportProChargesMutation } from "@webapps/shared/operations";
import { validateEmail } from "@webapps/shared/utils";
import { Form, Formik } from "formik";
import DownloadIcon from "images/icons/ri/download.svg?react";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { intersection } from "utils";

import { sanitizeFilters, sortingVariablesByState } from "../../utils";

interface FormValues {
  email: string;
}

type Props = {
  closeModal: () => void;
  isOpen: boolean;
};

const ExportModal = ({ isOpen, closeModal }: Props) => {
  const { t } = useTranslation();
  const focusInputRef = useInputFocus();
  const [error, setError] = useState<string | undefined>(undefined);
  const setNotification = useNotification((state) => state.setNotification);
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const sorting = useChargesState((state) => state.sorting);
  const filters = useChargesState.getState().filters;
  const sanitizedFilters = sanitizeFilters(filters);

  const currentAccountUserId = useUser((state) => state.currentAccountUserId);
  const [exportProChargesMutation, { loading }] = useExportProChargesMutation({
    onCompleted: (result) => {
      if (result?.exportProCharges?.successMessage) {
        setError(undefined);
        setNotification({ badge: true, message: result?.exportProCharges?.successMessage });
        closeModal();
        return;
      }
      if (result?.exportProCharges?.errors && result.exportProCharges.errors.length > 0) {
        setError(result.exportProCharges.errors.join(" "));
        return;
      }
    },
    onError: (error) => {
      closeModal();
      setNotification({ badge: true, message: error.message, type: NotificationCategoryEnum.Error });
    },
  });

  const submit = async (values: FormValues) => {
    await exportProChargesMutation({
      variables: {
        input: {
          accountUserId: currentAccountUserId ?? "none",
          email: values.email,
          filters: sanitizedFilters,
          sort: {
            sortBy: sortingVariablesByState[sorting.value],
            sortDirection: sorting.desc ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
          },
        },
      },
    });
    trackButtonClicked(BUTTON_CLICKED.EXPORT_CHARGES, {
      ...mixpanelDynamicProps,
    });
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={submit}
    >
      {({ errors, handleSubmit, touched, setFieldValue, values }) => {
        const hasError = intersection(Object.keys(errors), Object.keys(touched)).length > 0;

        const handleChangeEmail = async (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setFieldValue("email", value);
        };

        const disableButton = !values.email || hasError || loading;

        return (
          <ActionModal
            isOpen={isOpen}
            onClose={closeModal}
            cta={t("chargesPage.modal.cta")}
            title={t("chargesPage.modal.title")}
            subtitle={t("chargesPage.modal.subtitle")}
            cancelCta={t("chargesPage.modal.cancel")}
            showCloseButton
            icon={DownloadIcon}
            onCancel={closeModal}
            onConfirm={handleSubmit}
            loading={loading}
            disabled={disableButton}
          >
            <Form className="flex flex-col gap-6">
              <InputField
                name="email"
                label={t("chargesPage.modal.label")}
                validate={validateEmail}
                ref={focusInputRef}
                inputProps={{
                  autoComplete: "email",
                  className: "focus:border-2 focus:border-neutral-300 rounded-lg",
                  inputMode: "email",
                  onChange: handleChangeEmail,
                  type: "email",
                }}
              />
              {hasError ? <div className="text-base text-red-600">{t("form.fix", { ns: "common" })}</div> : null}

              {error && !hasError ? <div className="text-base text-red-600">{error}</div> : null}
            </Form>
          </ActionModal>
        );
      }}
    </Formik>
  );
};

export default ExportModal;
