import FilterSelect from "@webapps/apps/business/src/components/molecules/FilterSelect";
import { ChargesTableActionKind, useChargesState } from "@webapps/apps/business/src/hooks/useChargesState";
import useMixpanelDynamicProps from "@webapps/apps/business/src/hooks/useMixpanelDynamicProps";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED } from "@webapps/shared/libs";
import { ChargeSourceEnum } from "@webapps/shared/operations";
import { useTranslation } from "react-i18next";

import useCharges from "../../hooks/useCharges";

const PaymentFilter = () => {
  const { t } = useTranslation();
  const dispatch = useChargesState((state) => state.dispatch);
  const filters = useChargesState((state) => state.filters);
  const { refreshCharges } = useCharges();
  const { trackButtonClicked } = useMixpanel();
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  const onItemClick = (value: ChargeSourceEnum) => {
    const chargeSources = useChargesState.getState().filters.chargeSources;
    const isValueSelected = chargeSources?.includes(value);
    const hasPaymentFilter = chargeSources && chargeSources.length > 0;
    const newValue = isValueSelected
      ? chargeSources?.filter((payment) => payment !== value)
      : hasPaymentFilter
        ? [...chargeSources, value]
        : [value];
    if (newValue) {
      dispatch({ payload: { key: "chargeSources", value: newValue }, type: ChargesTableActionKind.SET_FILTER });
    }
  };

  const onClick = async () => {
    const filter_names = useChargesState.getState().filters.chargeSources;
    await refreshCharges();
    trackButtonClicked(BUTTON_CLICKED.FILTER_PAYMENT, {
      ...mixpanelDynamicProps,
      filter_names,
    });
  };

  const items = Object.values(ChargeSourceEnum).map((key) => ({
    label: t(`chargesPage.filters.payment.${key}`),
    value: key,
  }));
  const selectedItems = items.filter((item) => filters["chargeSources"]?.includes(item.value)) || [];

  return (
    <FilterSelect
      items={items}
      kind="checkbox"
      selectedItems={selectedItems}
      label={t("chargesPage.filters.payment.label")}
      size="large"
      onItemClick={onItemClick}
      onClick={onClick}
    />
  );
};

export default PaymentFilter;
