import { useApolloClient } from "@apollo/client";
import { AccountFragment, AccountFragmentDoc } from "@webapps/shared/operations";

import useSignedIn from "./useSignedIn";
import { useUser } from "./useUser";

export const usePeriodicPaymentMode = () => {
  const currentAccountId = useUser((state) => state.currentAccountId);
  const signedIn = useSignedIn();
  const client = useApolloClient();
  if (!signedIn) return undefined;
  const account = client.readFragment<AccountFragment>({
    fragment: AccountFragmentDoc,
    fragmentName: "AccountFragment",
    id: `Account:${currentAccountId}`,
  });
  return account?.proAccountInfo?.periodicPaymentMode;
};

export const useIsExternalPayment = () => {
  const periodicPaymentMode = usePeriodicPaymentMode();
  return periodicPaymentMode === "external";
};

export const useIsSepaPayment = () => {
  const periodicPaymentMode = usePeriodicPaymentMode();
  return periodicPaymentMode === "sepa";
};
