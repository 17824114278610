import { Banner } from "@webapps/shared/components";
import { BusinessCompanyFragment } from "@webapps/shared/operations";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import SubSubtitle from "../../../components/atoms/SubSubtitle";
import { getBillingItems } from "../utils";

import PaymentItem from "./PaymentItem";

interface Props {
  businessCompany: BusinessCompanyFragment | undefined;
  children?: never;
  loading?: boolean;
}

const BillingInfos: FunctionComponent<Props> = ({ businessCompany, loading }) => {
  const { t } = useTranslation();
  const billingItems = useMemo(() => getBillingItems(businessCompany), [businessCompany]);

  return (
    <Banner
      borderColor="primary-default"
      borderOpacity="10"
      padding="p-6"
      bgColor="bg-business-background"
      borderRadius="medium"
    >
      <div className="flex w-full flex-col gap-4">
        <SubSubtitle>{t("paymentPage.account.billing.title")}</SubSubtitle>
        <div className="flex flex-col">
          {billingItems.map((item, index) => (
            <PaymentItem {...item} key={`billing-item-${index}`} loading={loading} />
          ))}
        </div>
      </div>
    </Banner>
  );
};

export default BillingInfos;
