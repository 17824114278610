import { useMediaQuery } from "@react-hook/media-query";
import { Action, Button, Input, ActionModal } from "@webapps/shared/components";
import Page from "@webapps/shared/components/templates/Page";
import { useMixpanel } from "@webapps/shared/hooks";
import { BUTTON_CLICKED, FormType, PAGE_VIEWED } from "@webapps/shared/libs";
import UserAddIcon from "images/icons/ri/add.svg?react";
import ChevronRightIcon from "images/icons/ri/chevron-right.svg?react";
import map from "lodash/map";
import { ComponentProps, FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TitleSeparator from "../../../components/atoms/TitleSeparator";
import { useHasToSetupPayment } from "../../../hooks/useHasToSetupPayment";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import SearchIcon from "../../../images/icons/ri/search.svg?react";
import UserIcon from "../../../images/icons/ri/user.svg?react";
import SepaUsersBanner from "../components/SepaUsersBanner";
import UserSection from "../components/UserSection";

export interface LabelledItem {
  label: string;
}

export interface UserItem extends LabelledItem {
  accountUserId: string;
  active: boolean;
  admin: boolean;
  me: boolean;
}

export interface InvitationItem extends LabelledItem {
  canReinvite: boolean;
  invitationId: string;
  refused: boolean;
}

interface Props extends Pick<ComponentProps<typeof Page>, "navLeft"> {
  adminItems: Array<UserItem>;
  children?: never;
  isModalOpen: boolean;
  memberItems: Array<UserItem | InvitationItem>;
  notification?: ReactNode | undefined;
  reinviteUsers?: () => void;
  searchQuery?: string;
  setModalDisplayed: (open: boolean) => void;
  setSearchQuery?: (searchQuery: string) => void;
}

const Screen: FunctionComponent<Props> = ({
  adminItems,
  memberItems,
  navLeft,
  searchQuery = "",
  setSearchQuery,
  reinviteUsers,
  setModalDisplayed,
  isModalOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trackButtonClicked = useMixpanel((state) => state.trackButtonClicked);
  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");
  const hasToSetupPayment = useHasToSetupPayment();

  const handleRequestInvite = useCallback(() => {
    trackButtonClicked(BUTTON_CLICKED.REQUEST_INVITE_USER, {
      ...mixpanelDynamicProps,
      form_type: (mdScreen ? "multiple" : "single") as FormType,
    });

    navigate("/users/invite");
  }, [mdScreen, mixpanelDynamicProps, navigate, trackButtonClicked]);

  const navRight = useMemo(() => {
    if (mdScreen) {
      return (
        <Button
          onClick={handleRequestInvite}
          kind={reinviteUsers ? "secondary" : "primary"}
          size="small"
          label={t("usersPage.cta.inviteDrivers")}
          trailingIcon={UserAddIcon}
          disabled={hasToSetupPayment}
        />
      );
    }

    return (
      <Action
        icon={UserAddIcon}
        iconSize="small"
        horizontalPadding="medium"
        label={t("usersPage.cta.invite")}
        onClick={handleRequestInvite}
        title={t("usersPage.actionTitle")}
        disabled={hasToSetupPayment}
      />
    );
  }, [handleRequestInvite, mdScreen, t, reinviteUsers, hasToSetupPayment]);

  const formatMember = useCallback(
    (member: UserItem | InvitationItem) => {
      let href, leadingIcon, size, trailingDotHint, trailingHint;
      if ("invitationId" in member) {
        const { invitationId, refused, canReinvite } = member as InvitationItem;
        href = `/invitations/${invitationId}`;
        size = "small";
        trailingDotHint = refused || canReinvite;
        trailingHint = refused
          ? t("usersPage.declinedInvitation")
          : canReinvite
            ? t("usersPage.pendingInvitationWithReinvite")
            : t("usersPage.pendingInvitation");
      } else {
        const { accountUserId, admin, me } = member as UserItem;
        href = `/users/${accountUserId}`;
        leadingIcon = admin ? UserIcon : undefined;
        size = !admin ? "small" : undefined;
        trailingHint = me ? t("usersPage.trailingHint") : undefined;
      }

      return {
        ...member,
        href,
        leadingIcon,
        size,
        trailingDotHint,
        trailingHint,
        trailingIcon: ChevronRightIcon,
      };
    },
    [t]
  );

  const admins = useMemo(() => {
    if (adminItems.length === 0 && searchQuery === "") {
      return [
        {
          label: t("usersPage.noCreatedAdmin"),
        },
      ];
    }

    return map(adminItems, formatMember);
  }, [adminItems, searchQuery, formatMember, t]);

  const members = useMemo(() => {
    if (memberItems.length === 0 && searchQuery === "") {
      return [
        {
          label: t("usersPage.noCreatedUser"),
        },
      ];
    }

    return map(memberItems, formatMember);
  }, [formatMember, memberItems, searchQuery, t]);

  const membersSectionHeading = reinviteUsers ? (
    <Button onClick={() => setModalDisplayed(true)} kind="primary" size="small" label={t("usersPage.cta.reinvite")} />
  ) : null;

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.USERS_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ActionModal
        isOpen={isModalOpen}
        title={t("usersPage.infoModal.title")}
        cta={t("usersPage.infoModal.cta")}
        cancelCta={t("usersPage.infoModal.cancelCta")}
        onCancel={() => setModalDisplayed(false)}
        onConfirm={reinviteUsers}
        onClose={() => setModalDisplayed(false)}
      />
      <Page navLeft={navLeft} navRight={navRight} title={t("usersPage.pageTitle")} business rawContent>
        <TitleSeparator />
        <div className="pt-4-safe pb-2-safe md:py-4-safe px-4-safe md:px-8-safe flex flex-col items-start gap-6">
          {hasToSetupPayment && <SepaUsersBanner onSetupClick={() => navigate("/payment")} />}
          <div className="flex flex-col gap-2">
            <Input
              icon={SearchIcon}
              placeholder={t("usersPage.inputPlaceholder")}
              onChange={(event) => setSearchQuery?.(event.target.value)}
              onClear={() => setSearchQuery?.("")}
              value={searchQuery}
            />

            <div className="text-xs font-normal text-neutral-600">{t("usersPage.info")}</div>
          </div>

          {/* Search result */}
          {searchQuery !== "" && (admins.length > 0 || members.length > 0) && (
            <div className="pt-4 text-base font-normal text-neutral-900">{t("usersPage.resultTitle")}</div>
          )}

          {/* No results */}
          {searchQuery !== "" && admins.length === 0 && members.length === 0 && (
            <div className="text-base font-normal text-neutral-900">{t("usersPage.noResult")}</div>
          )}

          {/* Admins */}
          {(admins.length > 0 || searchQuery === "") && (
            <UserSection items={admins} title={t("usersPage.adminsSection")} />
          )}

          {/* Users */}
          {(members.length > 0 || searchQuery === "") && (
            <UserSection items={members} title={t("usersPage.membersSection")} sectionHeading={membersSectionHeading} />
          )}
        </div>
      </Page>
    </>
  );
};

export default Screen;
