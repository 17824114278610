import CustomSelect from "@webapps/apps/business/src/components/molecules/CustomSelect";
import { CountryFormObject } from "@webapps/shared/utils";
import { Input, FieldWrapper } from "components";
import { useField } from "formik";
import { ComponentProps, forwardRef } from "react";

export interface Props {
  callingCode: string;
  children?: never;
  inputProps?: ComponentProps<typeof Input>;
  items: CountryFormObject[];
  label: string;
  name: string;
  onClear?: () => void;
  prefixName: string;
  selectWidth?: string;
  serverError?: boolean;
  validate?: (value: string) => string | undefined;
}

const CustomPhoneNumberField = forwardRef<HTMLInputElement, Props>(
  ({ inputProps, label, callingCode, name, prefixName, validate, items, onClear, serverError = false }, ref) => {
    const [field, { error, initialValue, touched }, { setValue }] = useField<string>({ name, validate });
    const [prefixField, { error: prefixError }, { setValue: setPrefixValue }] = useField<string>({ name: prefixName });

    const handleClear = () => {
      if (onClear) {
        onClear();
      } else setValue((inputProps?.initialValue as string) ?? initialValue ?? "", false);
    };

    return (
      <FieldWrapper label={label} name={name} disabled={inputProps?.disabled}>
        <div className="relative flex gap-2">
          <CustomSelect
            items={items}
            invalid={!!prefixError}
            {...prefixField}
            displayedValue={callingCode}
            setValue={setPrefixValue}
            scrollToValue
          />

          <Input
            ref={ref}
            state={(error && touched) || serverError ? "invalid" : "none"}
            onClear={handleClear}
            {...field}
            {...inputProps}
            serverError={serverError}
          />
        </div>
      </FieldWrapper>
    );
  }
);

export default CustomPhoneNumberField;
