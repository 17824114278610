import { ChargesPage_ChargeFragment, useChargesPageLazyQuery } from "@webapps/shared/operations";

import { ChargesTableActionKind, PAGE_LENGTH, useChargesState } from "../../../hooks/useChargesState";
import { PaginationDirection } from "../components/ChargesPagination";
import { sanitizeFilters } from "../utils";

import useChargesQueryVariables from "./useChargesQueryVariables";

const useCharges = () => {
  const [getCharges] = useChargesPageLazyQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatch({
        payload: true,
        type: ChargesTableActionKind.SET_ERROR,
      });
    },
  });

  const dispatch = useChargesState((state) => state.dispatch);
  const chargesQueryVariables = useChargesQueryVariables();

  const refreshCharges = async () => {
    const filters = useChargesState.getState().filters;
    const sanitizedFilters = sanitizeFilters(filters);
    const result = await getCharges({
      variables: {
        ...chargesQueryVariables,
        filters: sanitizedFilters,
        first: PAGE_LENGTH,
      },
    });

    if (result?.data?.me?.account?.charges?.nodes) {
      dispatch({
        payload: {
          chargesCount: result.data.me.account.chargesCount,
          data: result.data.me.account.charges.nodes as ChargesPage_ChargeFragment[],
          pageInfo: result.data.me.account.charges.pageInfo,
        },
        type: ChargesTableActionKind.SET_DATA,
      });
      dispatch({ type: ChargesTableActionKind.RESET_PAGINATION });
    }
  };

  const getNextCharges = async (direction: PaginationDirection) => {
    const pageInfo = useChargesState.getState().pageInfo;
    const hasPreviousPage = pageInfo?.hasPreviousPage;
    const hasNextPage = pageInfo?.hasNextPage;
    if ((direction === "left" && !hasPreviousPage) || (direction === "right" && !hasNextPage)) {
      return;
    }
    const before = pageInfo?.startCursor ?? undefined;
    const after = pageInfo?.endCursor ?? undefined;
    const paginationVariables =
      direction === "left"
        ? { before, last: PAGE_LENGTH }
        : ({ after, first: PAGE_LENGTH } as Partial<{ after: undefined; before: string }>);
    const filters = useChargesState.getState().filters;
    const sanitizedFilters = sanitizeFilters(filters);
    const result = await getCharges({
      variables: { ...chargesQueryVariables, ...paginationVariables, filters: sanitizedFilters },
    });

    if (result?.data?.me?.account?.charges?.nodes) {
      dispatch({
        payload: {
          chargesCount: result.data.me.account.chargesCount,
          data: result.data.me.account.charges.nodes as ChargesPage_ChargeFragment[],
          pageInfo: result.data.me.account.charges.pageInfo,
        },
        type: ChargesTableActionKind.SET_DATA,
      });
    }
  };

  return { getCharges, getNextCharges, refreshCharges };
};

export default useCharges;
