import { Alignment } from "@webapps/shared/components/screens/LandingScreen";
import { Button, LandingIllustration, LandingScreen, FullPage, CenteredNavbar } from "components";
import { useDownloadChargesPageQuery } from "operations";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useUser } from "../../hooks/useUser";

interface Props {
  children?: never;
}

const DownloadChargesPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const currentAccountId = useUser((state) => state.currentAccountId);

  const { data, loading, error } = useDownloadChargesPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !currentAccountId,
    variables: {
      accountId: currentAccountId ?? "none",
    },
  });

  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("filename");

  const emailFileUrl =
    data?.me?.account?.accountUser?.csvChargesUrls?.find((file) => file.filename === fileName)?.url ?? undefined;

  const csvChargesUrl = emailFileUrl ?? data?.me?.account?.accountUser?.csvChargesUrl ?? undefined;

  const noExportAvailable = error || !csvChargesUrl;

  const landingScreenProps = useMemo(() => {
    return {
      alignment: "center" as Alignment,
      button: noExportAvailable ? (
        <Button size="small" kind="tertiary" href="/charges" label={t("downloadChargesPage.cta.backButton")}></Button>
      ) : (
        <div className="flex gap-3">
          <Button size="small" loading={loading} kind="primary">
            <a href={csvChargesUrl} download>
              {t("downloadChargesPage.cta.downloadButton")}
            </a>
          </Button>
          <Button size="small" kind="tertiary" href="/charges" label={t("downloadChargesPage.cta.backButton")}></Button>
        </div>
      ),
      content: noExportAvailable ? (
        <p>{t("downloadChargesPage.error.content")}</p>
      ) : (
        <p>{t("downloadChargesPage.success.content")}</p>
      ),
      illustration: noExportAvailable ? ("empty-station" as LandingIllustration) : ("man" as LandingIllustration),
      navbar: <CenteredNavbar className="mb-2" business={true} />,
      title: noExportAvailable ? t("downloadChargesPage.error.title") : t("downloadChargesPage.success.title"),
    };
  }, [t, loading, csvChargesUrl, noExportAvailable]);

  return (
    <FullPage withLightFooter={true} className="pt-4">
      <LandingScreen {...landingScreenProps} />
    </FullPage>
  );
};

export default DownloadChargesPage;
