import { Banner, Button } from "@webapps/shared/components";
import RightArrowIcon from "images/icons/ri/chevron-right.svg?react";

type Props = {
  bgColor?: string;
  ctaLabel: string;
  onClick: () => void;
  subtitle: string;
  title: string;
  withBorder?: boolean;
};

const ActionBanner = ({ onClick, title, subtitle, ctaLabel, bgColor, withBorder = true }: Props) => (
  <Banner
    borderColor={withBorder ? "primary-default" : undefined}
    borderOpacity={withBorder ? "10" : undefined}
    borderRadius="small"
    className="w-full"
    bgColor={bgColor}
  >
    <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
      <div className="flex flex-col">
        <span className="text-medium text-primary-default font-bold">{title}</span>
        <span className="text-label-sm text-secondary-default font-normal">{subtitle}</span>
      </div>
      <Button
        size="small"
        type="button"
        onClick={onClick}
        label={ctaLabel}
        trailingIcon={RightArrowIcon}
        className="self-end"
      />
    </div>
  </Banner>
);

export default ActionBanner;
