import { useMediaQuery } from "@react-hook/media-query";
import { Button, Page } from "@webapps/shared/components";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import classNames from "classnames";
import DownloadIcon from "images/icons/ri/download.svg?react";
import { ComponentProps, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "usehooks-ts";

import QueryError from "../../../components/molecules/QueryError";
import { PAGE_LENGTH, useChargesState } from "../../../hooks/useChargesState";
import useMixpanelDynamicProps from "../../../hooks/useMixpanelDynamicProps";
import ChargeCards from "../components/ChargeCards";
import ChargesPagination from "../components/ChargesPagination";
import ChargeTable from "../components/ChargeTable";
import DateFilters from "../components/DateFilters";
import EmptyCharges from "../components/EmptyCharges";
import ExportModal from "../components/ExportModal";
import FilterToggle from "../components/FilterToggle";
import PaymentFilter from "../components/PaymentFilter";
import SearchFilter from "../components/SearchFilter";
import SortBySelect from "../components/SortBySelect";
import { TableData } from "../utils";

const Screen: FunctionComponent<Pick<ComponentProps<typeof Page>, "navLeft">> = ({ navLeft }) => {
  const { t } = useTranslation();
  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();
  const state = useChargesState();
  const { data, chargesCount, initialChargesCount, error } = state;

  // we always need data to render as we must have the headers during loading state
  const dataToRender: TableData[] | {}[] = data ?? Array(15).fill({});
  // case where filters return no results
  const hasNoResults = !!(!data && initialChargesCount && initialChargesCount > 0);
  // case where user has no charges
  const hasEmptyCharges = !!(!data && initialChargesCount === 0);
  // we render UI only if user has charges or if filters have no results and if no error
  const renderUi = !error && !hasEmptyCharges;
  // we render pagination only if there are more charges than PAGE_LENGTH
  const renderPagination = !!(chargesCount && chargesCount > PAGE_LENGTH);

  const smScreen = useMediaQuery("only screen and (min-width: 640px)");
  const rootElementRef = useRef<HTMLDivElement>(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isFilterVisible, toggleFilter] = useToggle(true);

  //
  // Tracking plan
  //

  useEffect(() => {
    trackPageView(PAGE_VIEWED.CHARGES_PAGE, mixpanelDynamicProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      navLeft={navLeft}
      title={t("chargesPage.pageTitle")}
      rawContent
      business
      stretchContentOnLargeScreen
      headerRight={renderUi ? <FilterToggle toggleFilter={toggleFilter} isFilterVisible={isFilterVisible} /> : null}
    >
      {error && <QueryError />}
      {hasEmptyCharges && !error && <EmptyCharges />}
      {renderUi && (
        <>
          <ExportModal isOpen={isModalOpen} closeModal={() => setModalOpen(false)} />
          {isFilterVisible && (
            <div className="pt-4-safe pb-2-safe md:py-4-safe px-4-safe md:px-8-safe flex flex-col items-start gap-3">
              <SearchFilter />
              <div className="border-primary-default my-2 w-full border-t opacity-10 sm:hidden" />
              <div className="flex flex-col items-start gap-2 sm:flex-row md:flex-col lg:flex-row">
                <DateFilters />
                <PaymentFilter />
              </div>
            </div>
          )}

          <div
            className="border-primary-default mx-4 mb-0 mt-4 border-t opacity-10 md:mx-8 md:mb-2"
            ref={rootElementRef}
          />

          <div className="pb-4-safe md:py-4-safe px-4-safe md:px-8-safe flex flex-col gap-0 sm:h-full sm:gap-5">
            <div
              className={classNames(
                "sticky top-0 z-30 flex h-20 w-full items-center justify-between bg-white shadow-none sm:static sm:h-auto"
              )}
            >
              <SortBySelect />
              <div className="flex justify-between gap-2">
                <Button
                  size="small"
                  type="button"
                  onClick={() => setModalOpen(true)}
                  label={t("chargesPage.exportData")}
                  className="hidden sm:flex"
                  trailingIcon={DownloadIcon}
                  disabled={!!hasNoResults}
                />
                {renderPagination && <ChargesPagination />}
              </div>
            </div>

            {smScreen ? (
              <ChargeTable data={dataToRender} hasNoResults={hasNoResults} />
            ) : (
              <ChargeCards data={dataToRender} hasNoResults={hasNoResults} />
            )}

            {renderPagination && (
              <div className="flex justify-between sm:justify-end sm:px-0">
                <Button
                  size="small"
                  kind="secondary"
                  type="button"
                  onClick={() => {
                    rootElementRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                  }}
                  label={t("chargesPage.table.backToTop")}
                  className="sm:hidden"
                />
                <ChargesPagination />
              </div>
            )}
          </div>
        </>
      )}
    </Page>
  );
};

export default Screen;
