import { usePaymentPageQuery } from "@webapps/shared/operations";
import { FunctionComponent, useMemo } from "react";

import { useUser } from "../../hooks/useUser";

import Screen from "./screens/Screen";

const PaymentPage: FunctionComponent = () => {
  const currentAccountId = useUser((state) => state.currentAccountId);

  const queryOptions = currentAccountId
    ? {
        variables: { accountId: currentAccountId },
      }
    : { skip: true };

  const { data, loading, error, refetch } = usePaymentPageQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    ...queryOptions,
  });

  const accountInfo = useMemo(() => data?.me?.account?.proAccountInfo ?? undefined, [data]);

  /*
   * Props
   */

  const screenProps = useMemo(() => {
    return {
      accountInfo,
      error: !!error,
      loading: loading,
      refetch,
    };
  }, [accountInfo, loading, error, refetch]);

  //
  // Rendering
  //

  return <Screen {...screenProps} />;
};

export default PaymentPage;
