import { Button } from "@webapps/shared/components";
import { Form, Formik } from "formik";
import { useInputFocus } from "hooks";
import RightArrowIcon from "images/icons/ri/chevron-right.svg?react";
import { ChangeEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useScript } from "usehooks-ts";
import { required, validateIban } from "utils";

import InputItemField from "../../../components/molecules/InputItemField";
import QueryError from "../../../components/molecules/QueryError";
import { formatIban } from "../utils";

type Props = {
  holderName?: string;
  onConfirm: (formValues: IbanFormValues) => void;
};

export type IbanFormValues = {
  holderName: string;
  ibanNumber: string;
};

const initialValues: IbanFormValues = {
  holderName: "",
  ibanNumber: "",
};

const IbanForm: FunctionComponent<Props> = ({ onConfirm }) => {
  const { t } = useTranslation();
  const scriptUrl = import.meta.env.VITE_DOCUSIGN_SCRIPT_URL;

  const status = useScript(scriptUrl, {
    id: "docusign",
    removeOnUnmount: true,
  });

  const focusInputRef = useInputFocus();

  return (
    <Formik initialValues={initialValues} onSubmit={onConfirm}>
      {({ setFieldValue }) => {
        const handleChangeIban = (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          const formattedValue = formatIban(value);
          setFieldValue("ibanNumber", formattedValue);
        };

        return status === "error" ? (
          <QueryError />
        ) : (
          <Form className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <InputItemField
                loading={status === "loading"}
                name="holderName"
                label={t(`paymentPage.account.payment.account`)}
                validate={required}
                ref={focusInputRef}
                inputProps={{
                  className: "md:col-span-2",
                }}
              />

              <InputItemField
                name="ibanNumber"
                label={t(`paymentPage.account.payment.iban`)}
                validate={validateIban}
                inputProps={{
                  onChange: handleChangeIban,
                }}
              />
            </div>
            <Button
              size="small"
              onClick={() => {}}
              label={t("paymentPage.account.payment.cta")}
              trailingIcon={RightArrowIcon}
              className="self-end"
              type="submit"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default IbanForm;
