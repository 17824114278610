import classNames from "classnames";

type colorMode = "light" | "medium";

const LoadingText = ({ colorMode = "light" }: { colorMode?: colorMode }) => {
  return (
    <div
      className={classNames("animate-skeleton rounded-xs z-100 h-4 bg-gradient-to-r from-0% via-50% to-100%", {
        "from-skeleton-light to-skeleton-medium via-[#F4F5F6]": colorMode === "light",
        "from-skeleton-medium via-[#dadfe0] to-[#ced4d6]": colorMode === "medium",
      })}
    />
  );
};

export default LoadingText;
