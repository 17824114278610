import { ActionModal } from "@webapps/shared/components";
import { useTranslation } from "react-i18next";

type Props = {
  isModalDisplayed: boolean;
  loading: boolean;
  onSubmitIbanForm: () => void;
  setModalDisplayed: (value: boolean) => void;
  showSigning: boolean;
};

const SigninModal = ({ isModalDisplayed, setModalDisplayed, onSubmitIbanForm, loading, showSigning }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`${showSigning ? "z-100" : "z-0"} flex h-full w-full items-center justify-center`}
        id="docusignContainer"
      />
      {!showSigning && (
        <ActionModal
          isOpen={isModalDisplayed}
          title={t("paymentPage.account.payment.infoModal.title")}
          subtitle={t("paymentPage.account.payment.infoModal.subtitle")}
          cta={t("paymentPage.account.payment.infoModal.cta")}
          cancelCta={t("paymentPage.account.payment.infoModal.cancelCta")}
          onCancel={() => setModalDisplayed(false)}
          onConfirm={onSubmitIbanForm}
          onClose={() => setModalDisplayed(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default SigninModal;
