import { Tooltip } from "@webapps/shared/components";
import {
  ChargesPage_ChargeFragment,
  Currency,
  ChargeSourceEnum,
  ChargeSortKeyEnum,
  SortDirectionEnum,
  ChargeFiltersType,
} from "@webapps/shared/operations";
import { castDateOrString, formatCurrency, formatEnergy, formatDuration, formatPercent } from "@webapps/shared/utils";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { ChargesTableState, Filters } from "../../../hooks/useChargesState";
import ReceiptIcon from "../../../images/icons/ri/receipt.svg?react";
import CellContentWithTooltip from "../components/CellContentWithTooltip";
import CellLoader from "../components/CellLoader";

export type TableKey = keyof TableData;

export type TableData = {
  amount: { currency: Currency; value: number };
  date: string;
  duration: number;
  energy: number;
  identification: { name?: string; phoneNumber?: string };
  payment: ChargeSourceEnum | undefined;
  receipt: string | undefined;
  soc: { end: number | string; start: number | string };
  station: string;
  vehicle: { licensePlate: string | undefined; name: string | undefined };
};

export const sortingVariablesByState = {
  amount: ChargeSortKeyEnum.TotalAmountCents,
  date: ChargeSortKeyEnum.At,
};

export const getQueryVariablesFromState = (state: ChargesTableState) => {
  const { filters, pagination, sorting } = state;
  const { pageIndex, pageSize } = pagination;
  const { desc, value } = sorting;

  return {
    first: pageSize,
    offset: pageIndex * pageSize,
    sortBy: sortingVariablesByState[value],
    sortDirection: desc ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
    ...filters,
  };
};

// we prefer forEach over map here because we want to preserve the order of the data
export const sanitizeData = (data: ChargesPage_ChargeFragment[]) => {
  const sanitizedArray: TableData[] = [];
  data.forEach((charge) => {
    const {
      driverFullname,
      driverPhoneNumber,
      date,
      car,
      station,
      totalDuration,
      totalEnergy,
      totalAmountCents,
      invoice,
      currency,
      source,
      startSoc,
      endSoc,
      accountTagLabel,
    } = charge;
    const identification = accountTagLabel
      ? { name: accountTagLabel }
      : {
          name: driverFullname ?? undefined,
          phoneNumber: driverPhoneNumber ?? undefined,
        };
    const result = {
      amount: { currency, value: totalAmountCents },
      date,
      duration: totalDuration ?? 0,
      energy: totalEnergy ?? 0,
      identification,
      payment: source ?? undefined,
      receipt: invoice?.fileUrl ?? undefined,
      soc: { end: endSoc ?? "?", start: startSoc ?? "?" },
      station: station?.name,
      vehicle: { licensePlate: car?.friendlyIdentifier ?? undefined, name: car?.friendlyName ?? undefined },
    };
    sanitizedArray.push(result);
  });
  return sanitizedArray as TableData[];
};

export const sanitizeFilters = (filters: Filters) => {
  const date = filters.date;
  const startDate = date && "startDate" in date && date?.startDate ? castDateOrString(date.startDate) : undefined;
  const endDate = date && "endDate" in date && date?.endDate ? castDateOrString(date.endDate) : undefined;
  const sanitizedFilters = filters?.date ? { ...filters, date: { ...filters.date, endDate, startDate } } : filters;
  return sanitizedFilters as ChargeFiltersType;
};

function isTableData(data: TableData | {}): data is TableData {
  return (
    "amount" in data &&
    "date" in data &&
    "duration" in data &&
    "energy" in data &&
    "identification" in data &&
    "payment" in data &&
    "receipt" in data &&
    "soc" in data &&
    "station" in data &&
    "vehicle" in data
  );
}

export const renderData = (data: TableData | {}, isMobile = false) => {
  const isEmpty = !isTableData(data);
  return [
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={110} />
        ) : data.identification.name || data.identification.phoneNumber ? (
          <CellContentWithTooltip
            value={data.identification.name ?? "-"}
            subtitle={data.identification.phoneNumber}
            title={t("chargesPage.table.identification", { ns: "business" })}
            width={110}
          />
        ) : (
          "-"
        ),
      header: () => t("chargesPage.table.identification", { ns: "business" }),
      headerSize: 110,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={110} />
        ) : (
          <CellContentWithTooltip
            title={t("chargesPage.table.date", { ns: "business" })}
            value={t("format.time", {
              formatParams: {
                val: { dateStyle: "medium", timeStyle: "short" },
              },
              ns: "common",
              val: castDateOrString(data.date),
            })}
            width={110}
          />
        ),
      header: () => t("chargesPage.table.date", { ns: "business" }),
      headerSize: 110,
    },

    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={110} />
        ) : data.vehicle.name ? (
          <CellContentWithTooltip
            value={data.vehicle.name}
            subtitle={data.vehicle.licensePlate}
            title={t("chargesPage.table.vehicle", { ns: "business" })}
            width={110}
          />
        ) : (
          "-"
        ),
      header: () => t("chargesPage.table.vehicle", { ns: "business" }),
      headerSize: 110,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={110} />
        ) : (
          <CellContentWithTooltip
            title={t("chargesPage.table.station", { ns: "business" })}
            value={data.station}
            width={110}
          />
        ),
      header: () => t("chargesPage.table.station", { ns: "business" }),
      headerSize: 110,
    },
    {
      cell: () => (isEmpty ? <CellLoader width={60} /> : formatDuration(data.duration)),
      header: () => t("chargesPage.table.duration", { ns: "business" }),
      headerSize: 60,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={60} />
        ) : (
          <CellContentWithTooltip
            title={t("chargesPage.table.energy", { ns: "business" })}
            value={formatEnergy(data.energy)}
            width={60}
          />
        ),
      header: () => t("chargesPage.table.energy", { ns: "business" }),
      headerSize: 60,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={70} />
        ) : (
          <CellContentWithTooltip
            title={<Trans i18nKey="chargesPage.table.soc" components={{ 1: <span className="hidden"></span> }} />}
            value={t("chargesPage.table.socValue", {
              end: typeof data.soc.end === "number" ? formatPercent(data.soc.end) : data.soc.end,
              start: typeof data.soc.start === "number" ? formatPercent(data.soc.start) : data.soc.start,
            })}
            width={70}
          />
        ),
      header: () =>
        isMobile ? (
          <Trans i18nKey="chargesPage.table.soc" components={{ 1: <span className="hidden"></span> }} />
        ) : (
          <div className="flex flex-col">
            <Trans
              i18nKey="chargesPage.table.soc"
              components={{ 1: <span className="text-secondary-default text-xs font-normal"></span> }}
            />
          </div>
        ),
      headerSize: 70,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={46} />
        ) : (
          formatCurrency({ currency: data.amount.currency, value: data.amount.value })
        ),
      header: () =>
        isMobile ? (
          <Trans i18nKey="chargesPage.table.amount" components={{ 1: <span /> }} />
        ) : (
          <div className="flex flex-col">
            <Trans
              i18nKey="chargesPage.table.amount"
              components={{ 1: <span className="text-secondary-default text-xs font-normal"></span> }}
            />
          </div>
        ),
      headerSize: 46,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={70} />
        ) : data.payment ? (
          t(`chargesPage.table.source.${data.payment}`, { ns: "business" })
        ) : (
          "-"
        ),
      header: () => t("chargesPage.table.payment", { ns: "business" }),
      headerSize: 70,
    },
    {
      cell: () =>
        isEmpty ? (
          <CellLoader width={43} />
        ) : (
          <Tooltip
            content={
              <div className="flex flex-col whitespace-pre text-xs">
                <span className="font-bold">{t("chargesPage.table.receipt", { ns: "business" })}</span>
                <span className="font-normal">{t("chargesPage.table.download", { ns: "business" })}</span>
              </div>
            }
          >
            <a href={data.receipt} className="flex w-full items-center justify-center">
              <ReceiptIcon className="text-icon-default hidden h-4 sm:block" />
              <span className="font-normal sm:hidden">
                {t("chargesPage.table.downloadReceipt", { ns: "business" })}
              </span>
            </a>
          </Tooltip>
        ),
      header: () => (
        <div className="flex sm:items-center sm:justify-center">
          {t("chargesPage.table.receipt", { ns: "business" })}
        </div>
      ),
      headerSize: 43,
    },
  ];
};
