import { Button } from "@webapps/shared/components";
import { Trans, useTranslation } from "react-i18next";

const QueryError = () => {
  const { t } = useTranslation();

  return (
    <div className="px-4-safe md:px-8-safe py-4-safe flex h-full flex-col items-start justify-center gap-6">
      <div className="text-primary-default after:content-sparkles text-4xl font-light after:-ml-3 after:inline-block after:w-8 after:align-super">
        <Trans i18nKey="queryError.title" components={{ 1: <br /> }} />
      </div>

      <div className="text-secondary-default text-xl font-light">{t("queryError.subtitle")}</div>
      <Button size="small" label={t("queryError.cta")} onClick={() => window.location.reload()} />
    </div>
  );
};

export default QueryError;
